import { flow, types, Instance } from 'mobx-state-tree';
import { Document } from './models';
import { States } from '../types/types';
import { http } from '../http';
import { searchQuery } from '../utils/parsers';

const storeName = 'DocumentsStore';
export const DocumentsStore = types
  .model(storeName, {
    items: types.optional(types.array(Document), []),
    total: types.optional(types.number, 0),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
  })
  .actions((self) => ({
    fetch: flow(function* fetch(page = 1, search = {}) {
      self.loadingState = States.Pending;
      try {
        const response = yield http.get(`documents?page=${page}${searchQuery(search)}`);

        self.loadingState = States.Done;
        self.total = +response.data.total;
        const records = response.data.documents
          .map((r) => {
            r.id = +r.id;
            r.dateStart = new Date(r.dateStart);
            r.dateEnd = r.dateEnd && new Date(r.dateEnd);
            r.dateEstimate = !!+r.dateEstimate;
            return r;
          });


        self.items = records;
      } catch (error) {
        console.error('Failed to fetch projects', error);
        self.loadingState = States.Error;
      }
    }),
  }));

export interface DocumentsStore extends Instance<typeof DocumentsStore> {}
