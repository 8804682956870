const white = '#fff';
const gray100 = '#fafafa';
const gray200 = '#f1f1f1';
const gray300 = '#bfbdbe';
const gray400 = '#333';
const black = '#000';

const burgundy = '#952a2a';
const burgundyLight = '#952a2acc';

const mainText = gray400;

const primary = burgundy;
const primaryLight = burgundyLight;
const header = gray200;
const headerButton = gray300;
const footer = gray200;
const pageButton = gray200;

export const colors = {
  white,
  black,
  mainText,
  primary,
  primaryLight,
  header,
  headerButton,
  footer,
  pageButton,
  gray100,
  gray200,
  gray300,
  gray400,
};

export const dimension = { input: '32px' };

export const breakpoints = {
  xs: '500px',
  s: '760px',
  m: '964px',
};
