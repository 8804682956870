import { observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Table as TTable, Thead, Tbody, OverflowWrapper, CarouselCustom } from '../../../components-ui';
import { Person } from './Person';
import { PersonPhoto } from '../../../stores';


interface PersonsTable {
  photos: Array<PersonPhoto>;
}

export const Table: FunctionComponent<PersonsTable> = observer(({ photos }) => {
  const { t } = useTranslation();
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [showingPerson, setShowingPerson] = useState(PersonPhoto.create());

  const openLightbox = (person: PersonPhoto): void => {
    setShowingPerson(person);
    setViewerIsOpen(true);
  };

  const closeLightbox = (): void => {
    setViewerIsOpen(false);
  };

  return (
    <OverflowWrapper>
      <TTable>
        <Thead>
          <tr>
            <th>{t('Фамилия, имя')}</th>
            <th>{t('Заголовок документа')}</th>
            <th>{t('Место съемки')}</th>
            <th>{t('Дата съемки')}</th>
            <th>{t('Автор съемки')}</th>
            <th>{t('Примечание')}</th>
            <th>{t('Смотреть фотографию')}</th>
          </tr>
        </Thead>
        <Tbody>
          {photos.map((item) => (
            <Person key={item.id} person={item} openLightbox={openLightbox} />
          ))}
          {!!showingPerson.source.fileUrl && (
            <CarouselCustom
              viewerIsOpen={viewerIsOpen}
              onClose={closeLightbox}
              currentIndex={0}
              views={[{
                src: showingPerson.source.fileUrl,
                caption: showingPerson.source.title,
              }]}
            />
          )}
        </Tbody>
      </TTable>
    </OverflowWrapper>
  );
});
