import styled from 'styled-components';
import { colors } from '../components-ui';

export const TabBlock = styled.nav`
  margin: 0 4px;
  align-items: center;
  display: flex;
  font-family: Roboto;
  height: 75px;
  justify-content: space-between;
`;

export const TabList = styled.ul`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${colors.gray100};
`;

export const Presentation = styled.li`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: 16px;
`;

export const Tab = styled.a`
  color: ${colors.black};
  padding: 8px 4px;
  width: 100%;
  border-bottom: 3px solid ${colors.white};

  &:hover,
  &.active {
    border-color: ${colors.primary};
    text-decoration: none;
  }
`;
