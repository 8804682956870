import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Photo } from '../../stores';
import { ListItem, ListItemInfo, Cover } from '../../components-ui';

interface DocumentGrid {
    photos: Array<Photo>;
    offset: number;
}

const PhotoGrid: FunctionComponent<DocumentGrid> = (props) => {
  const { t, i18n } = useTranslation();
  const { photos, offset } = props;

  return (
    <>
      {photos.map((photo, number) => (
        <ListItem key={photo.id} to={photo.link} target="_blank">
          <Cover url={photo.fileUrl} label="Фото отсутствует" />
          <ListItemInfo>
            <div>
              <p>{number + 1 + offset}. {photo.title}</p>
              <p>{t('Место съемки')}: {photo.shootPlace || t('Не установлено')}</p>
              <p>{t('Дата съемки')}: {photo.dateFormat}</p>
              <p>{t('Автор съемки')}: {photo.author ? photo.author[i18n.language] : t('Не установлен')}</p>
            </div>
          </ListItemInfo>
        </ListItem>
      ))}
    </>
  );
};

export default observer(PhotoGrid);
