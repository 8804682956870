import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ImageWrapper } from '../../components-ui';
import { links } from '../../utils/pages';

const Full: FunctionComponent = () => (
  <ImageWrapper>
    <img alt="Карта Карелии" src="/client/assets/images/map1.jpg" />
    <Link
      to={`${links.search}/14`}
      title="Княжая губа"
      target="_blank"
      style={{ position: 'absolute', left: '34.3%', top: '12.65%', width: '20.93%', height: '1.9%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/25`}
      title="Палкина губа"
      target="_blank"
      style={
      { position: 'absolute', left: '34.3%', top: '10.48%', width: '17.11%', height: '1.56%', zIndex: 2 }
    }
    />
    <Link
      to={`${links.search}/20`}
      title="Нива"
      target="_blank"
      style={{ position: 'absolute', left: '35.88%', top: '7.25%', width: '8.31%', height: '1.62%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/10`}
      title="Кандалакша"
      target="_blank"
      style={{ position: 'absolute', left: '18.85%', top: '8.92%', width: '18.36%', height: '1.45%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/34`}
      title="Тумча"
      target="_blank"
      style={{ position: 'absolute', left: '21.1%', top: '15.72%', width: '12.71%', height: '1.78%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/21`}
      title="Оланга"
      target="_blank"
      style={{ position: 'absolute', left: '18.19%', top: '19.96%', width: '11.46%', height: '1.73%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/38`}
      title="Шапкозеро"
      target="_blank"
      style={{ position: 'absolute', left: '42.52%', top: '23.47%', width: '2.16%', height: '1.51%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/38`}
      title="Шапкозеро"
      target="_blank"
      style={{ position: 'absolute', left: '27.49%', top: '21.79%', width: '15.03%', height: '3.18%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/12`}
      title="Кереть"
      target="_blank"
      style={{ position: 'absolute', left: '42.69%', top: '21.18%', width: '9.72%', height: '2.06%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/36`}
      title="Ухта"
      target="_blank"
      style={{ position: 'absolute', left: '20.6%', top: '34.39%', width: '10.05%', height: '1.84%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/1`}
      title="Авнепорог"
      target="_blank"
      style={{ position: 'absolute', left: '41.61%', top: '39.41%', width: '2.33%', height: '1.11%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/1`}
      title="Авнепорог"
      target="_blank"
      style={{ position: 'absolute', left: '41.61%', top: '36.62%', width: '15.95%', height: '2.79%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/11`}
      title="Кемь"
      target="_blank"
      style={{ position: 'absolute', left: '48.59%', top: '39.58%', width: '9.22%', height: '1.73%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/35`}
      title="Тунгуда"
      target="_blank"
      style={{ position: 'absolute', left: '26.16%', top: '45.99%', width: '13.54%', height: '2.17%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/18`}
      title="Луголамбина"
      target="_blank"
      style={{ position: 'absolute', left: '42.77%', top: '46.38%', width: '2.41%', height: '1.62%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/18`}
      title="Луголамбина"
      target="_blank"
      style={{ position: 'absolute', left: '44.19%', top: '47.71%', width: '16.03%', height: '2.17%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/2`}
      title="Андронова гора"
      target="_blank"
      style={{ position: 'absolute', left: '7.97%', top: '49.05%', width: '22.76%', height: '1.78%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/28`}
      title="Ругозеро"
      target="_blank"
      style={{ position: 'absolute', left: '31.73%', top: '50.33%', width: '14.37%', height: '1.67%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/33`}
      title="Тумба"
      target="_blank"
      style={{ position: 'absolute', left: '23.09%', top: '60.59%', width: '11.54%', height: '1.9%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/23`}
      title="Паданы"
      target="_blank"
      style={{ position: 'absolute', left: '35.71%', top: '59.42%', width: '11.88%', height: '3.01%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/29`}
      title="Сегозеро"
      target="_blank"
      style={{ position: 'absolute', left: '42.19%', top: '62.54%', width: '14.12%', height: '1.73%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/6`}
      title="Гирвас"
      target="_blank"
      style={{ position: 'absolute', left: '28.57%', top: '72.07%', width: '11.3%', height: '1.84%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/40`}
      title="Шуньга"
      target="_blank"
      style={{ position: 'absolute', left: '48.5%', top: '70.57%', width: '12.04%', height: '2.17%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/15`}
      title="Кондопога"
      target="_blank"
      style={{ position: 'absolute', left: '41.94%', top: '75.86%', width: '1.66%', height: '1.11%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/15`}
      title="Кондопога"
      target="_blank"
      style={{ position: 'absolute', left: '43.6%', top: '75.86%', width: '15.2%', height: '1.84%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/13`}
      title="Кивач"
      target="_blank"
      style={{ position: 'absolute', left: '30.9%', top: '74.64%', width: '10.8%', height: '1.67%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/8`}
      title="Интерпоселок"
      target="_blank"
      style={{ position: 'absolute', left: '16.78%', top: '86.68%', width: '18.36%', height: '2.06%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/7`}
      title="Ильинский"
      target="_blank"
      style={{ position: 'absolute', left: '16.69%', top: '90.64%', width: '10.63%', height: '1.84%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/22`}
      title="Олонец"
      target="_blank"
      style={{ position: 'absolute', left: '27.66%', top: '91.14%', width: '1.83%', height: '1.34%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/22`}
      title="Олонец"
      target="_blank"
      style={{ position: 'absolute', left: '29.49%', top: '91.14%', width: '9.97%', height: '1.9%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/32`}
      title="Сяде (коммуна)"
      target="_blank"
      style={{ position: 'absolute', left: '22.26%', top: '92.59%', width: '7.06%', height: '1.39%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/39`}
      title="Шокша"
      target="_blank"
      style={{ position: 'absolute', left: '47.92%', top: '85.95%', width: '13.46%', height: '1.95%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/26`}
      title="Петрозаводск"
      target="_blank"
      style={{ position: 'absolute', left: '42.11%', top: '81.44%', width: '20.35%', height: '1.78%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/31`}
      title="Суна"
      target="_blank"
      style={{ position: 'absolute', left: '34.97%', top: '77.09%', width: '8.55%', height: '1.45%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/3`}
      title="Сорока"
      target="_blank"
      style={{ position: 'absolute', left: '49.42%', top: '45.15%', width: '11.96%', height: '1.84%', zIndex: 2 }}
    />
  </ImageWrapper>
);

export default Full;
