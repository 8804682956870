import styled from 'styled-components';
import { colors } from './Settings';

export const BaseButton = styled.button`
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    opacity: .25;
  }
`;

export const LargeButton = styled(BaseButton)`
  min-width: 128px;
`;

export const InfoButton = styled(BaseButton)`
  &:disabled {
    color: ${colors.black};
    opacity: .5;
  }
`;

export const NoStyleButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  svg {
    color: ${colors.mainText};
  }
`;

export const LinkButton = styled(NoStyleButton)`
  font-size: inherit;
  line-height: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

export const LanguageButton = styled(NoStyleButton)`
  width: 42px;
  height: 42px;
  border-radius: 0;
  background: ${colors.headerButton};

  &.active,
  &:hover {
    background: ${colors.primary};
    color: ${colors.white};
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;

  button {
    & + button {
      margin: 0 0 0 8px;
    }
  }

  p {
    font-size: 12px;
    margin: 0 0 0 8px;
    width: 50%;
  }
`;

export const PagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

const Page = styled(NoStyleButton)`
  width: 28px;
  height: 28px;
  line-height: 28px;
  color: ${colors.black};
  background-color: ${colors.pageButton};
  margin: 0 8px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
`;

export const RangePage = styled(Page)`
  background-color: ${colors.white};

  &:hover{
    background-color: ${colors.pageButton};
  }
`;

export const NumberPage = styled(Page)`
  &.active,
  &:hover {
    color: ${colors.white};
    background-color: ${colors.primary};

    svg {
      color: ${colors.white};
    }
  }
`;
