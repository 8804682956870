import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, Row } from '../components-ui';
import Maps from './Maps';

const RowText = styled(Row)`
  margin-bottom: 12px;

  p {
    text-indent: 12px;
    padding: 4px 8px;
  }
`;

const Preface: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <RowText>
        <p>{t('Предисловие-1')}</p>
        <p>{t('Предисловие-2')}</p>
      </RowText>
      <RowText>
        <p>{t('Предисловие-3')}</p>
        <p>{t('Предисловие-4')}</p>
        <p>{t('Предисловие-5')}</p>
      </RowText>
      <RowText>
        <p>{t('Предисловие-6')}</p>
        <p>{t('Предисловие-7')}</p>
        <p>{t('Предисловие-8')}</p>
        <p>{t('Предисловие-9')}</p>
      </RowText>
      <RowText>
        <p>{t('Предисловие-10')}</p>
        <p>{t('Предисловие-11')}</p>
        <p>{t('Предисловие-12')}</p>
        <p>{t('Предисловие-13')}</p>
      </RowText>
      <RowText>
        <p>{t('Предисловие-14')}</p>
        <p>{t('Предисловие-15')}</p>
        <p>{t('Предисловие-16')}</p>
      </RowText>
      <Maps />
    </Container>
  );
};

export default Preface;
