import styled from 'styled-components';
import React, { useState, FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoStyleButton } from '../components-ui';
import { Alphabet } from './Alphabet';
import { colors, dimension, breakpoints } from './Settings';
import { AdaptiveWrapper } from './Wrappers';

export const SearchWrapper = styled.div`
  padding: 24px 0;

  & > div:not(:last-child) {
    padding-bottom: 32px;
  }
`;

export const InputWrapper = styled(AdaptiveWrapper)`
  div:first-child {
    margin-right: 32px;
  }

  p {
    line-height: ${dimension.input};
  }

  @media (max-width: ${breakpoints.xs}) {
    div:first-child {
      margin-right: 0;
    }
  }
`;

const InputColumnWrapper = styled(InputWrapper)`
  flex-direction: column;
`;

export const SearchInput = styled.div`
  position: relative;

  input {
    font-size: 14px;
    min-width: 400px;
    height: ${dimension.input};
    border: 1px solid ${colors.black};
    border-radius: 3px;
  }

  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
    
    input {
      min-width: unset;
      width: 100%
    }
  }
`;

const InputButton = styled(NoStyleButton)`
  position: absolute;
  right: 8px;
  font-size: 14px;
  height: ${dimension.input};
`;

const InputLeftButton = styled(InputButton)`
  right: 24px;
`;

const Input = styled.input`
  padding: 0 42px 0 10px;
`;

export const InputForm: FunctionComponent = ({ value, onChange, onClick, onClear, title }) => {
  const [input, setInput] = useState(value);
  const [typingTimeout, settypingTimeout] = useState(0);

  const handleEnter = (event): void => {
    if (event.key === 'Enter') {
      onChange(input);
    }
  };

  const handleChange = (e): void => {
    const oldValue = input;
    const searchText = e.target.value;
    setInput(searchText);
    if (searchText.length > 2) {
      if (typingTimeout) clearTimeout(typingTimeout);
      settypingTimeout(setTimeout(() => {
        onChange(searchText);
      }, 500));
    }
    if (searchText.length < 3 && oldValue.length > searchText.length) {
      onChange('');
    }
  };

  return (
    <>
      <Input
        type="text"
        value={input}
        onChange={(e): void => handleChange(e)}
        onKeyPress={(e): void => handleEnter(e)}
      />
      {!!input.length && (
        <InputLeftButton
          title={title}
          onClick={
            (): void => {
              setInput('');
              onClear();
            }
          }
        >
          <FontAwesomeIcon
            icon={['fas', 'times']}
            className="fa-fw"
          />
        </InputLeftButton>
      )}
      <InputButton title={title} onClick={onClick}>
        <FontAwesomeIcon
          icon={['fas', 'search']}
          className="fa-fw"
        />
      </InputButton>
    </>
  );
};

export const InputFormWrapper = ({ value, onChange, onClick, onClear, title, hint }): JSX.Element => (
  <InputWrapper>
    <SearchInput>
      <InputForm
        value={value}
        onChange={onChange}
        onClick={onClick}
        onClear={onClear}
        title={title}
      />
    </SearchInput>
    <p>{hint}</p>
  </InputWrapper>
);

export const InputFormAlphabetWrapper = (
  { value, lang, onChange, onClick, onClear, title, hint, letterChange }
): JSX.Element => (
  <InputColumnWrapper>
    <InputFormWrapper
      value={value}
      onChange={onChange}
      onClick={onClick}
      onClear={onClear}
      title={title}
      hint={hint}
    />
    <Alphabet
      lang={lang}
      onClick={letterChange}
    />
  </InputColumnWrapper>
);
