import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { LinkButton, colors, Row, Container } from '../components-ui';

const ColourSpan = styled.span`
  color: ${colors.primary};
`;

export const NoMatch: FunctionComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <p>{t('К сожалению, страницы не найдено.')}</p>
        <p>
          <Trans i18nKey="Попробуйте вернуться">
            Попробуйте вернуться <ColourSpan as={LinkButton} onClick={(): void => history.goBack()}>назад</ColourSpan>
            или на <ColourSpan as={Link} to="/">главную страницу</ColourSpan>
          </Trans>
        </p>
      </Row>
    </Container>
  );
};
