import React, { FunctionComponent, MouseEvent } from 'react';
import Pagination from 'react-paginating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PagesWrapper, NumberPage } from '.';
import { FETCH_COUNT, PAGINATION } from '../utils/const';

interface PaginationProps {
    total: number;
    currentPageNumber: number;
    handlePageChange: (page?: number, event?: MouseEvent) => void;
}

export const ListPagination: FunctionComponent<PaginationProps> = (props) => {
  const { total, currentPageNumber, handlePageChange } = props;
  if (total <= FETCH_COUNT) return null;

  return (
    <Pagination
      total={total}
      limit={FETCH_COUNT}
      pageCount={PAGINATION}
      currentPage={currentPageNumber}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps,
      }): JSX.Element => (
        <PagesWrapper>
          {currentPage !== 1 && (
            <NumberPage
              {...getPageItemProps({
                total,
                pageValue: 1,
                onPageChange: handlePageChange,
              })}
            >
              <FontAwesomeIcon
                icon={['fas', 'angle-double-left']}
                className="fa-fw"
              />
            </NumberPage>
          )}

          {hasPreviousPage && (
            <NumberPage
              {...getPageItemProps({
                total,
                pageValue: previousPage,
                onPageChange: handlePageChange,
              })}
            >
              {
                <FontAwesomeIcon
                  icon={['fas', 'angle-left']}
                  className="fa-fw"
                />
                        }
            </NumberPage>
          )}

          {pages.map((page) => {
            let activePage;
            if (currentPage === page) {
              activePage = 'active';
            }
            return (
              <NumberPage
                key={page}
                {...getPageItemProps({
                  total,
                  pageValue: page,
                  className: activePage,
                  onPageChange: handlePageChange,
                })}
              >
                {page}
              </NumberPage>
            );
          })}

          {hasNextPage && (
            <NumberPage
              {...getPageItemProps({
                total,
                pageValue: nextPage,
                onPageChange: handlePageChange,
              })}
            >
              {
                <FontAwesomeIcon
                  icon={['fas', 'angle-right']}
                  className="fa-fw"
                />
                        }
            </NumberPage>
          )}

          {currentPage !== totalPages && (
            <NumberPage
              {...getPageItemProps({
                total,
                pageValue: totalPages,
                onPageChange: handlePageChange,
              })}
            >
              <FontAwesomeIcon
                icon={['fas', 'angle-double-right']}
                className="fa-fw"
              />
            </NumberPage>
          )}
        </PagesWrapper>
      )}
    </Pagination>
  );
};
