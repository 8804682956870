import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Full, Section } from './MapsImages';
import { AdaptiveWrapper } from '../components-ui';

const Wrapper = styled(AdaptiveWrapper)`
  @media (max-width: 430px) {
    flex-direction: column;
  }
`;

const HalfPage = styled.div`
  flex: 50%;
  padding: 0 16px;
  margin: 16px 0;
`;

const Maps: FunctionComponent = () => (
  <Wrapper>
    <HalfPage>
      <Full />
    </HalfPage>
    <HalfPage>
      <Section />
    </HalfPage>
  </Wrapper>
);

export default Maps;
