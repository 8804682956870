import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Document } from '../../stores';
import { ListItem, ListItemInfo, Cover } from '../../components-ui';

interface DocumentGrid {
  documents: Array<Document>;
  offset: number;
}

const DocumentGrid: FunctionComponent<DocumentGrid> = (props) => {
  const { t } = useTranslation();
  const { documents, offset } = props;

  return (
    <>
      {documents.map((document, number) => (
        <ListItem key={document.id} to={document.link} target="_blank">
          <Cover url={document.fileUrl} />
          <ListItemInfo>
            <div>
              <p>
                {number + 1 + offset}. {document.title}
              </p>
              <p>{document.dateFormat}</p>
              {document.comment && <p>{t('Примечание')}: {document.comment}</p>}
            </div>
            <p>
              {document.isDocument ? (
                <FontAwesomeIcon icon={['fas', 'archive']} className="fa-fw" />
              ) : (
                <FontAwesomeIcon
                  icon={['fas', 'newspaper']}
                  className="fa-fw"
                />
              )}
              {document.references}
            </p>
          </ListItemInfo>
        </ListItem>
      ))}
    </>
  );
};

export default observer(DocumentGrid);
