import { flow, types } from 'mobx-state-tree';
import { Place } from './models';
import { States, Languages } from '../types/types';
import { http } from '../http';
import { defaultLanguage } from '../utils/const';

const storeName = 'WorkPlacesStore';
export const WorkPlacesStore = types
  .model(storeName, {
    items: types.optional(types.array(Place), []),
    total: types.optional(types.number, 0),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
  })
  .views((self) => ({
    get lang(): string {
      return localStorage.getItem('i18nextLng') || defaultLanguage;
    },
    get needFetch(): boolean {
      return !!self.items.length || (self.loadingState === States.Pending || self.loadingState === States.Done);
    },
  }))
  .actions((self) => ({
    convert(language) {
      const array = [...self.items];
      array.sort((a, b) => a[language].localeCompare(b[language], language));
      array.push(array.splice(array.findIndex((item) => item.type === 'no_data'), 1)[0]);

      return array.map((item) => ({
        label: item[language],
        value: item.id,
      }));
    },
    fetch: flow(function* fetch() {
      self.loadingState = States.Pending;
      try {
        const response = yield http.get('workplaces', { headers: { 'X-Accept-Language': Languages[self.lang] } });

        const records = response.data
          .map((r) => {
            r.id = +r.id;
            return r;
          });


        self.items = records;
        self.total = records.length;
        self.loadingState = States.Done;
      } catch (error) {
        console.error('Failed to fetch workplaces', error);
        self.loadingState = States.Error;
      }
    }),
  }));
