import styled from 'styled-components';
import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, dimension, breakpoints } from './Settings';
import { AdaptiveWrapper } from './Wrappers';

export const SelectWrapper = styled.div`
  & > span {
    color: ${colors.primary};
    font-size: 14px;
  
    svg {
      padding-right: 4px;
    }
  }
  
  & > div {
    padding-top: 8px;
  }

  .search-container {
    margin-right: 0;
    
    & > div {
      @media (max-width: ${breakpoints.xs}) {
        min-width: unset;
        width: 100%;
      }
    }
  }
`;

export const SelectsWrapper = styled(AdaptiveWrapper)`
  & > div:not(:last-child) {
    padding-right: 64px;
    padding-bottom: 32px;
  }

  @media (max-width: ${breakpoints.xs}) {
    & > div {
      width: 100%;

      &:not(:last-child) {
        padding-right: 0;
      }
    }
  }
`;

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: colors.gray200,
  borderRadius: '2em',
  color: colors.mainText,
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const customStyles = {
  container: (provided) => ({
    ...provided,
    borderColor: 'red',
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: '400px',
    minWidth: '400px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: dimension.input,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  control: (provided, state) => ({
    ...provided,
    maxWidth: '400px',
    minWidth: '400px',
    minHeight: dimension.input,
    height: dimension.input,
    borderColor: colors.black,
    borderWidth: state.isFocused ? '1px' : '1px',
    boxShadow: state.isFocused ? 'none' : 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: colors.black,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px',
    color: colors.mainText,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px',
    color: colors.mainText,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px',
    color: colors.mainText,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  input: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const customColors = {
  primary25: '#932a2a40',
  primary50: '#932a2a80',
  primary75: '#952a2abf',
  primary: colors.primary,
  neutral30: colors.black,
  neutral40: colors.black,
};

export const SelectForm = ({ options, placeholder, noOptionsMessage, onChange, isClearable, defaultValue, isDisabled = false }): JSX.Element => (
  <Select
    styles={customStyles}
    className="search-container"
    theme={(theme) => ({
      ...theme,
      borderRadius: '3px',
      colors: {
        ...theme.colors,
        ...customColors,
      },
    })}
    defaultValue={defaultValue}
    options={options}
    formatGroupLabel={formatGroupLabel}
    placeholder={placeholder}
    noOptionsMessage={noOptionsMessage}
    onChange={onChange}
    isClearable={isClearable}
    isDisabled={isDisabled}
  />
);

export const SelectFormWrapper = (props): JSX.Element => {
  const { options, placeholder, noOptionsMessage, onChange, isClearable, icon, hint, defaultValue, isDisabled } = props;
  return (
    <SelectWrapper>
      <span>
        <FontAwesomeIcon
          icon={['fas', icon]}
          className="fa-fw"
        />
        {hint}
      </span>
      <SelectForm
        defaultValue={defaultValue}
        options={options}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
    </SelectWrapper>
  );
};
