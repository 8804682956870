export enum States {
  Pending = 'Pending',
  Done = 'Done',
  Error = 'Error',
  Initial = 'Initial'
}

export enum PlaceTypes {
  Locality = 'locality',
  Region = 'region',
  NoData = 'no_data'
}

export enum Homeland {
  USA = 'USA',
  Canada = 'Canada',
  NorthAmerica = 'North America',
}

export interface Opacity {
  semi: boolean;
}

export enum DateTypes {
  Full = 'full',
  Year = 'year',
  NotLaterFull = 'not_later_full',
  NotLaterYear = 'not_later_year',
  NotEarlierFull = 'not_earlier_full',
  NotEarlierYear = 'not_earlier_year',
  Decase = 'decase',
  Range = 'range'
}

export enum Entities {
  Documents = 'documents',
  Photos = 'photos'
}

export enum Languages {
  ru = 'rus',
  fi = 'fin'
}