import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoStyleButton } from '../../components-ui';

interface Props {
  toggleSideNav: () => void;
}

const Button = styled(NoStyleButton)`
  font-size: 20px;
  padding: 8px;
  align-self: baseline;
`;

const Hamburger: FunctionComponent<Props> = ({ toggleSideNav }) => {
  const { t } = useTranslation();

  return (
    <Button className="mobile-show" onClick={toggleSideNav} title={t('Открыть меню')}>
      <FontAwesomeIcon
        icon={['fas', 'bars']}
        className="fa-fw"
      />
    </Button>
  );
};

export default Hamburger;
