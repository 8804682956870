import { inject, observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { ValueType } from 'react-select';
import { RootStore } from '../../rootStore';
import withFetch from '../../utils/withFetch';
import {
  PageWrapper, SelectsWrapper, SelectFormWrapper,
  InputFormAlphabetWrapper,
  SearchWrapper
} from '../../components-ui';
import { States, PlaceTypes, Homeland } from '../../types/types';
import { DocumentsView, PhotosView } from '.';

type OptionType = {label: string; value: string }

interface PersonsSearch {
  store: RootStore;
}

interface ComponentProps {
  props: PersonsSearch;
}

const fetchDocs = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { documents } = this.props.store.personsStore;
    if (documents.docsFetch) {
      return Promise.resolve();
    }
    return documents.fetch();
  };

  return Promise.resolve(fetchEntities());
});

const fetchPhotos = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { photos } = this.props.store.personsStore;
    if (photos.photosFetch) {
      return Promise.resolve();
    }
    return photos.fetch();
  };

  return Promise.resolve(fetchEntities());
});

const fetchPlaces = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { placesStore } = this.props.store;
    if (placesStore.needFetch) {
      return Promise.resolve();
    }
    return placesStore.fetch();
  };

  return Promise.resolve(fetchEntities());
});

const fetchWorkPlaces = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { workPlacesStore } = this.props.store;
    if (workPlacesStore.needFetch) {
      return Promise.resolve();
    }
    return workPlacesStore.fetch();
  };

  return Promise.resolve(fetchEntities());
});

const PersonsSearch: FunctionComponent<PersonsSearch> = ({ store }) => {
  const { t, i18n } = useTranslation();
  const { personsStore, placesStore, workPlacesStore } = store;
  const { documents, photos, searchHistory } = personsStore;

  const [currentDocPage, setCurrentDocPage] = useState(1);
  const [currentPhotoPage, setCurrentPhotoPage] = useState(1);

  const [search, setSearch] = useState(searchHistory);

  const nameChange = (value): void => {
    const searchNew = {
      ...search,
      name: value,
    };
    setSearch(searchNew);
    if (value.length > 2) {
      personsStore.setSearch(searchNew);
      setCurrentDocPage(1);
      setCurrentPhotoPage(1);
      personsStore.documents.fetch(1, searchNew);
      personsStore.photos.fetch(1, searchNew);
    }
  };

  const letterChange = (letter): void => {
    const searchNew = {
      ...search,
      letter,
    };
    setSearch(searchNew);
    personsStore.setSearch(searchNew);
    setCurrentDocPage(1);
    setCurrentPhotoPage(1);
    personsStore.documents.fetch(1, searchNew);
    personsStore.photos.fetch(1, searchNew);
  };

  const searchPersons = (evt): void => {
    const searchNew = {
      ...search,
      ...evt,
    };
    setSearch(searchNew);
    personsStore.setSearch(searchNew);
    setCurrentDocPage(1);
    setCurrentPhotoPage(1);
    personsStore.documents.fetch(1, searchNew);
    personsStore.photos.fetch(1, searchNew);
  };

  const searchStore = (): void => {
    setCurrentDocPage(1);
    setCurrentPhotoPage(1);
    personsStore.documents.fetch(1, search);
    personsStore.photos.fetch(1, search);
  };

  const labels = {
    locality: t(PlaceTypes.Locality),
    region: t(PlaceTypes.Region),
    noData: t(PlaceTypes.NoData),
  };


  const options = [
    { value: '1', label: t(Homeland.USA) },
    { value: '2', label: t(Homeland.Canada) },
    { value: '3', label: t(Homeland.NorthAmerica) },
  ];

  return (
    <PageWrapper>
      <h2>{t('Поиск по персоналиям')}</h2>

      <SearchWrapper>
        <InputFormAlphabetWrapper
          value={search.name}
          lang={i18n.language}
          onChange={nameChange}
          letterChange={letterChange}
          onClick={searchStore}
          onClear={(): void => searchPersons({ name: '' })}
          title={t('Искать')}
          hint={t('Поиск по фамилии, имени')}
        />

        <SelectFormWrapper
          icon="map-signs"
          hint={t('Откуда прибыл(а)')}
          options={options}
          placeholder={t('Выберите место')}
          noOptionsMessage={(): string => t('Не найдено')}
          onChange={(e: ValueType<OptionType>): void => searchPersons({ homeland: e ? e.value : '' })}
          isClearable
        />

        <SelectsWrapper>
          {placesStore.loadingState === States.Done && !!placesStore.total && (
            <SelectFormWrapper
              icon="map-marker-alt"
              hint={t('Место нахождения/место жительства')}
              options={placesStore.group(i18n.language, labels)}
              placeholder={t('Выберите место жительства')}
              noOptionsMessage={(): string => t('Не найдено')}
              onChange={(e: ValueType<OptionType>): void => searchPersons({ place: e ? `${e.value}` : '' })}
              isClearable
            />
          )}

          {workPlacesStore.loadingState === States.Done && !!workPlacesStore.total && (
            <SelectFormWrapper
              icon="building"
              hint={t('Место работы')}
              options={workPlacesStore.convert(i18n.language)}
              placeholder={t('Выберите место работы')}
              noOptionsMessage={(): string => t('Не найдено')}
              onChange={(e: ValueType<OptionType>): void => searchPersons({ workplace: e ? `${e.value}` : '' })}
              isClearable
            />
          )}
        </SelectsWrapper>
      </SearchWrapper>

      <DocumentsView
        documents={documents}
        search={search}
        currentPage={currentDocPage}
        setCurrentPage={setCurrentDocPage}
      />
      <PhotosView
        photos={photos}
        search={search}
        currentPage={currentPhotoPage}
        setCurrentPage={setCurrentPhotoPage}
      />
    </PageWrapper>
  );
};

export default compose(
  inject('store'),
  fetchDocs,
  fetchPlaces,
  fetchWorkPlaces,
  fetchPhotos,
  observer
)(PersonsSearch);
