import { inject, observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { RootStore } from '../../rootStore';
import withFetch from '../../utils/withFetch';
import {
  PageWrapper,
  ItalicPar,
  ItemInfo, ItemImages, IconLink,
  Loader, CarouselCustom,
  ItemWrapper
} from '../../components-ui';
import { States } from '../../types/types';
import { links } from '../../utils/pages';

const ItemImage = styled(ItemImages)`
  text-align: left;
  cursor: pointer;
  img {
    max-width: 100%;
  }
`;

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  store: RootStore;
}

interface ComponentProps {
  props: Props;
}

const fetchData = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { store, match } = this.props;
    const { photoStore } = store;

    if (!!photoStore.title) {
      return Promise.resolve();
    }
    return photoStore.fetch(match.params.id);
  };

  return Promise.resolve(fetchEntities());
});

const Photo: FunctionComponent<Props> = ({ store }) => {
  const { t, i18n } = useTranslation();
  const photo = store.photoStore;

  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (): void => {
    setViewerIsOpen(true);
  };

  const closeLightbox = (): void => {
    setViewerIsOpen(false);
  };

  return (
    <PageWrapper>
      <IconLink to={links.photos}>
        <FontAwesomeIcon
          icon={['fas', 'arrow-circle-left']}
          className="fa-fw"
        />
        {t('Вернуться к списку')}
      </IconLink>

      {photo.loadingState === States.Pending && <Loader />}
      {photo.loadingState === States.Error && (
        <p>Фотография не найдена</p>
      )}
      {photo.loadingState === States.Done && (
        <>
          <h2>{photo.title}</h2>
          <ItemWrapper>
            <ItemInfo>
              <p>
                <FontAwesomeIcon
                  icon={['fas', 'calendar']}
                  className="fa-fw"
                />
                {photo.dateFormat}
              </p>
              {!!photo.places.length && (
              <p>
                <FontAwesomeIcon
                  icon={['fas', 'map-marker-alt']}
                  className="fa-fw"
                />
                {photo.places.map((place) => place[i18n.language]).join(', ')}
              </p>
              )}
              {!!photo.workplaces.length && (
              <p>
                <FontAwesomeIcon
                  icon={['fas', 'building']}
                  className="fa-fw"
                />
                {photo.workplaces.map((place) => place[i18n.language]).join(', ')}
              </p>
              )}
              {!!photo.comment && (
              <ItalicPar>{t('Примечание')}: {photo.comment}</ItalicPar>
              )}
            </ItemInfo>
            <ItemImage>
              <img src={photo.fileUrl} onClick={openLightbox} alt={photo.title} />
              {!!photo.fileUrl && (
                <CarouselCustom
                  viewerIsOpen={viewerIsOpen}
                  onClose={closeLightbox}
                  currentIndex={0}
                  views={[{
                    src: photo.fileUrl,
                    caption: photo.title,
                  }]}
                />
              )}
            </ItemImage>
          </ItemWrapper>
        </>
      )}
    </PageWrapper>
  );
};

export default compose(
  inject('store'),
  fetchData,
  observer
)(Photo);
