import { inject, observer } from 'mobx-react';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { RootStore } from '../../rootStore';
import DocumentGrid from './Grid';
import withFetch from '../../utils/withFetch';
import {
  Opacity,
  PageWrapper,
  Loader,
  ListPagination,
  SearchIcon,
  ListWrapper
} from '../../components-ui';
import { States } from '../../types/types';
import { links } from '../../utils/pages';

interface Props {
  store: RootStore;
}

interface ComponentProps {
  props: Props;
}

const fetchData = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { documentsStore } = this.props.store;

    if (documentsStore.items.length) {
      return Promise.resolve();
    }
    return documentsStore.fetch();
  };

  return Promise.resolve(fetchEntities());
});

const DocumentsList: FunctionComponent<Props> = ({ store }) => {
  const { t } = useTranslation();
  const { documentsStore } = store;
  const { items, total, loadingState } = documentsStore;

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const listRef = useRef(null);
  const [pageSwitched, setPageSwitched] = useState(false);

  const handlePageChange = (page): void => {
    setPageSwitched(true);
    setCurrentPage(page);
    setOffset(Math.ceil((page - 1) * 10));
    documentsStore.fetch(page);
  };

  useEffect(() => {
    if (pageSwitched && loadingState === States.Done && !!total) {
      listRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [loadingState]);

  return (
    <PageWrapper ref={listRef}>
      <ListWrapper>
        <h2>{t('Документы')}</h2>
        <SearchIcon to={links.search}>
          <FontAwesomeIcon
            icon={['fas', 'search']}
            className="fa-fw"
          />
          {t('Поиск по электронному архиву')}
        </SearchIcon>
      </ListWrapper>

      {loadingState === States.Pending && !total && <Loader />}

      <Opacity semi={loadingState === States.Pending}>
        {loadingState === States.Done && !total && (
          <div>{t('Документов не найдено')}</div>
        )}

        {!!total && (
          <div>
            <DocumentGrid documents={items} offset={offset} />

            <ListPagination
              total={total}
              currentPageNumber={currentPage}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
      </Opacity>
    </PageWrapper>
  );
};

export default compose(inject('store'), fetchData, observer)(DocumentsList);
