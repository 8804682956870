import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PhotosList, Photo } from '../views/Photos';
import { NoMatch } from './NoMatch';
import { links } from '../utils/pages';

const Photos: FunctionComponent = () => (
  <Switch>
    <Route exact path={links.photos} component={PhotosList} />
    <Route exact path={`${links.photos}/:id`} component={Photo} />
    <Route component={NoMatch} />
  </Switch>
);

export default Photos;
