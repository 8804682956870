import { getEnv, Instance, types } from 'mobx-state-tree';
import { Document, DocumentsStore, SearchStore, PhotosStore, PlacesStore, WorkPlacesStore, Photo, PersonsStore } from './stores';

const RootStore = types
  .model('RootStore', {
    documentsStore: types.optional(DocumentsStore, {}),
    personsStore: types.optional(PersonsStore, {}),
    searchStore: types.optional(SearchStore, {}),
    photosStore: types.optional(PhotosStore, {}),
    documentStore: types.optional(Document, {}),
    photoStore: types.optional(Photo, {}),
    placesStore: types.optional(PlacesStore, {}),
    workPlacesStore: types.optional(WorkPlacesStore, {}),
  })
  .views((self) => ({
    get apiClient() {
      return getEnv(self).api;
    },
  }));


export interface RootStore extends Instance<typeof RootStore> {
}

export default RootStore;
