import { inject, observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { ValueType } from 'react-select';

import { RootStore } from '../../rootStore';
import withFetch from '../../utils/withFetch';
import {
  PageWrapper,
  SelectsWrapper, InputFormWrapper, SelectFormWrapper,
  TabBlock, TabList, Tab, Presentation, SearchWrapper
} from '../../components-ui';
import { States, PlaceTypes, Entities } from '../../types/types';
import { SearchDocs } from '../../stores';
import { Documents, Photos } from '.';

type OptionType = {label: string; value: string }

interface MatchParams {
  place: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  store: RootStore;
}

interface ComponentProps {
  props: Props;
}

const fetchPlaces = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { placesStore } = this.props.store;
    if (placesStore.needFetch) {
      return Promise.resolve();
    }
    return placesStore.fetch();
  };

  return Promise.resolve(fetchEntities());
});

const fetchWorkPlaces = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { workPlacesStore } = this.props.store;
    if (workPlacesStore.needFetch) {
      return Promise.resolve();
    }
    return workPlacesStore.fetch();
  };

  return Promise.resolve(fetchEntities());
});

const Search: FunctionComponent<Props> = ({ store, match }) => {
  const { t, i18n } = useTranslation();
  const { searchStore, placesStore, workPlacesStore } = store;
  const { documents, photos, searchHistory } = searchStore;

  const [currentDocPage, setCurrentDocPage] = useState(1);
  const [currentPhotoPage, setCurrentPhotoPage] = useState(1);
  const [offsetDoc, setOffsetDoc] = useState(0);
  const [offsetPhoto, setOffsetPhoto] = useState(0);

  const [activeView, setActiveView] = useState(Entities.Documents as string);
  const [activeStore, setActiveStore] = useState(documents);
  const [search, setSearch] = useState(
    match.params.place
      ? SearchDocs.create({ place: match.params.place })
      : searchHistory
  );
  const [searchChanged, setSearchChanged] = useState(false);
  const [blockedSelect, setBlockedSelect] = useState('');

  const searchChange = (evt, block?): void => {
    if (block) {
      setBlockedSelect(block.status ? block.selectId : '');
    }
    const searchNew = {
      ...search,
      ...evt,
    };
    setSearch(searchNew);
    searchStore.setSearch(searchNew);
    setSearchChanged(true);
    if (activeView === Entities.Documents) {
      setCurrentDocPage(1);
      setOffsetDoc(0);
    } else if (activeView === Entities.Photos) {
      setCurrentPhotoPage(1);
      setOffsetPhoto(0);
    }
    activeStore.fetch(1, searchNew);
  };

  const labels = {
    locality: t(PlaceTypes.Locality),
    region: t(PlaceTypes.Region),
    noData: t(PlaceTypes.NoData),
  };

  const changeView = (view: string): void => {
    setActiveView(view);
    switch (view) {
      case Entities.Documents: {
        setActiveStore(documents);
        if (searchChanged) {
          setCurrentDocPage(1);
          setOffsetDoc(0);
          documents.fetch(1, search);
        }
        break;
      }
      case Entities.Photos: {
        setActiveStore(photos);
        if (searchChanged) {
          setCurrentPhotoPage(1);
          setOffsetPhoto(0);
          photos.fetch(1, search);
        }
        break;
      }
      // no default
    }
    setSearchChanged(false);
  };

  enum SelectId {
    Place = 'place',
    Workplace = 'workplace'
  }

  return (
    <PageWrapper>
      <h2>{t('Поиск по электронному архиву')}</h2>

      <SearchWrapper>
        <InputFormWrapper
          value={search.title}
          onChange={(e): void => searchChange({ title: e })}
          onClick={searchStore}
          onClear={(): void => searchChange({ title: '' })}
          title={t('Искать')}
          hint={t('Для поиска введите название документа')}
        />

        <SelectsWrapper>
          {placesStore.loadingState === States.Done && !!placesStore.total && (
            <SelectFormWrapper
              defaultValue={placesStore.find(+search.place, i18n.language)}
              icon="map-marker-alt"
              hint={t('Место нахождения/место жительства')}
              options={placesStore.group(i18n.language, labels)}
              placeholder={t('Выберите место жительства')}
              noOptionsMessage={(): string => t('Не найдено')}
              onChange={(e: ValueType<OptionType>): void => searchChange(
                { place: e ? `${e.value}` : '' },
                { selectId: SelectId.Workplace, status: !!e },
              )}
              isClearable
              isDisabled={blockedSelect === SelectId.Place}
            />
          )}

          {workPlacesStore.loadingState === States.Done && !!workPlacesStore.total && (
            <SelectFormWrapper
              icon="building"
              hint={t('Место работы')}
              options={workPlacesStore.convert(i18n.language)}
              placeholder={t('Выберите место работы')}
              noOptionsMessage={(): string => t('Не найдено')}
              onChange={(e: ValueType<OptionType>): void => searchChange(
                { workplace: e ? `${e.value}` : '' },
                { selectId: SelectId.Place, status: !!e },
              )}
              isClearable
              isDisabled={blockedSelect === SelectId.Workplace}
            />
          )}
        </SelectsWrapper>
      </SearchWrapper>

      <TabBlock>
        <TabList role="tablist">
          <Presentation role="presentation">
            <Tab
              role="tab"
              onClick={(): void => changeView(Entities.Documents)}
              className={activeView === Entities.Documents ? 'active' : ''}
            >
              {t('Документы')}
            </Tab>
          </Presentation>
          <Presentation role="presentation">
            <Tab
              role="tab"
              onClick={(): void => changeView(Entities.Photos)}
              className={activeView === Entities.Photos ? 'active' : ''}
            >
              {t('Фотографии')}
            </Tab>
          </Presentation>
        </TabList>
      </TabBlock>

      <Documents
        store={documents}
        search={search}
        hide={activeView !== Entities.Documents}
        currentPage={currentDocPage}
        setCurrentPage={setCurrentDocPage}
        offset={offsetDoc}
        setOffset={setOffsetDoc}
      />
      <Photos
        store={photos}
        search={search}
        hide={activeView !== Entities.Photos}
        currentPage={currentPhotoPage}
        setCurrentPage={setCurrentPhotoPage}
        offset={offsetPhoto}
        setOffset={setOffsetPhoto}
      />

    </PageWrapper>
  );
};

export default compose(
  inject('store'),
  fetchPlaces,
  fetchWorkPlaces,
  observer
)(Search);
