import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoints, colors, Row } from '../components-ui';

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakpoints.m}) {
    flex-direction: row;
  }

  background: ${colors.footer};
  font-family: Roboto;
  padding: 16px 36px;
  font-size: 14px;
  line-height: 1.4;

  p {
      margin-bottom: 16px;

      span {
          display: block;
          padding-left: 16px;
      }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.m}) {
    margin-left: 40px;
  }

  img {
    object-fit: contain;
    width: 100px;

    &:not(:first-child) {
      margin-left: 40px;
    }

    @media (min-width: ${breakpoints.s}) {
      justify-content: normal;
    }

    @media (min-width: ${breakpoints.m}) {
      width: 150px;
    }
  }
`;

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <div>
        <Row>
          <p>
            {t('Национальный архив Республики Карелия')}
          </p>
        </Row>
        <Row>
          <p>
            {t('Подготовлено в рамка проекта')}
            <span>«Североамериканские финны в лесной промышленности
              Cоветской Карелии в 1920‑1930‑е гг.»‎
            </span>
            <span>«Kanadan ja Amerikan suomalaiset Neuvosto‑Karjalan
              metsätaloutta rakentamassa 1920‑1930‑luvulla»
            </span>
          </p>
        </Row>
      </div>
      <ImageWrapper>
        <img src="/client/assets/images/logo-rus.png" alt="logo-rus" width="150" />
        <img src="/client/assets/images/logo-fin.png" alt="logo-fin" width="150" />
      </ImageWrapper>
    </FooterWrapper>
  );
};

export default Footer;
