import { flow, Instance, types, getRoot } from 'mobx-state-tree';
import { PersonDocument, PersonPhoto, SearchPersons, Photo, Document, Translations } from './models';
import { States, Languages } from '../types/types';
import { http } from '../http';
import { searchQuery } from '../utils/parsers';
import { defaultLanguage } from '../utils/const';

const PersonsDocuments = types
  .model('PersonsDocuments', {
    items: types.optional(types.array(PersonDocument), []),
    total: types.optional(types.number, 0),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
  })
  .views((self) => ({
    get lang(): string {
      return localStorage.getItem('i18nextLng') || defaultLanguage;
    },
    get docsFetch(): boolean {
      return !!self.items.length
        || (self.loadingState === States.Pending || self.loadingState === States.Done);
    },
  }))
  .actions((self) => {
    const fetch = flow(function* fetch(page = 1, search = {}) {
      self.loadingState = States.Pending;
      try {
        const response = yield http.get(
          `persons?source=documents&page=${page}${searchQuery(search)}`,
          { headers: { 'X-Accept-Language': Languages[self.lang] } }
        );

        self.total = +response.data.total;

        self.items = response.data.persons
          .map((r) => {
            r.id = +r.id;
            r.surnameRus = r.surnameRus;
            r.nameRus = r.nameRus;
            r.surnameFin = r.surnameFin;
            r.nameFin = r.nameFin;
            r.homeland = r.homeland;
            r.personPages = r.personPages;
            r.source = Document.create({
              id: +r.documentId,
              dateEnd: r.dateEnd && new Date(r.dateEnd),
              dateEstimate: !!+r.dateEstimate,
              dateStart: new Date(r.dateStart),
              dateType: r.dateType,
              comment: r.comment,
              title: r.title,
              editionNumber: r.editionNumber,
              editionYear: r.editionYear,
              fileNumber: r.fileNumber,
              fundNumber: r.fundNumber,
              pages: r.pages,
              periodicalsTitle: r.periodicalsTitle,
              seriesNumber: r.seriesNumber,
              fileUrl: r.fileUrl,
            });

            return r;
          });

        self.loadingState = States.Done;
      } catch (error) {
        console.error('Failed to fetch persons documents', error);
        self.loadingState = States.Error;
      }
    });
    return { fetch };
  });

export interface PersonsDocuments extends Instance<typeof PersonsDocuments> {}

const PersonsPhotos = types
  .model('PersonsPhotos', {
    items: types.optional(types.array(PersonPhoto), []),
    total: types.optional(types.number, 0),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
  })
  .views((self) => ({
    get lang(): string {
      return localStorage.getItem('i18nextLng') || defaultLanguage;
    },
    get photosFetch(): boolean {
      return !!self.items.length
        || (self.loadingState === States.Pending || self.loadingState === States.Done);
    },
  }))
  .actions((self) => {
    const fetch = flow(function* fetch(page = 1, search = {}) {
      self.loadingState = States.Pending;
      try {
        const response = yield http.get(
          `persons?source=photos&page=${page}${searchQuery(search)}`,
          { headers: { 'X-Accept-Language': Languages[self.lang] } }
        );

        self.total = +response.data.total;

        self.items = response.data.persons
          .map((r) => {
            r.id = +r.id;
            r.surnameRus = r.surnameRus;
            r.nameRus = r.nameRus;
            r.surnameFin = r.surnameFin;
            r.nameFin = r.nameFin;
            r.source = Photo.create({
              id: +r.photoId,
              dateEnd: r.dateEnd && new Date(r.dateEnd),
              dateEstimate: !!+r.dateEstimate,
              dateStart: new Date(r.dateStart),
              dateType: r.dateType,
              comment: r.comment,
              title: r.title,
              author: Translations.create({
                ru: r.authorRus || '',
                fi: r.authorFin || '',
              }),
              shootPlace: r.shootPlace,
              fileUrl: r.fileUrl,
            });
            return r;
          });

        self.loadingState = States.Done;
      } catch (error) {
        console.error('Failed to fetch persons photos', error);
        self.loadingState = States.Error;
      }
    });
    return { fetch };
  });

export interface PersonsPhotos extends Instance<typeof PersonsPhotos> {}

const storeName = 'PersonsStore';
export const PersonsStore = types
  .model(storeName, {
    documents: types.optional(PersonsDocuments, {}),
    photos: types.optional(PersonsPhotos, {}),
    searchHistory: types.optional(SearchPersons, {}),
  })
  .actions((self) => ({
    setSearch(search: SearchPersons): void {
      self.searchHistory = search;
    },
  }));
