import { flow, Instance, types } from 'mobx-state-tree';
import { Photo } from './models';
import { States } from '../types/types';
import { http } from '../http';
import { searchQuery } from '../utils/parsers';

const storeName = 'PhotosStore';
export const PhotosStore = types
  .model(storeName, {
    items: types.optional(types.array(Photo), []),
    total: types.optional(types.number, 0),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
  })
  .actions((self) => ({
    fetch: flow(function* fetch(page = 1, search = {}) {
      self.loadingState = States.Pending;
      try {
        const response = yield http.get(`photos?page=${page}${searchQuery(search)}`);

        self.loadingState = States.Done;
        self.total = +response.data.total;
        const records = response.data.photos
          .map((r) => {
            r.id = +r.id;
            r.dateStart = new Date(r.dateStart);
            r.dateEnd = r.dateEnd && new Date(r.dateEnd);
            r.dateEstimate = !!+r.dateEstimate;
            r.author = (r.authorRus || r.authorFin) && {
              ru: r.authorRus,
              fi: r.authorFin,
            };
            return r;
          });


        self.items = records;
      } catch (error) {
        console.error('Failed to fetch projects', error);
        self.loadingState = States.Error;
      }
    }),
  }));

export interface PhotosStore extends Instance<typeof PhotosStore> {}
