import { flow, getRoot, Instance, types, isAlive } from 'mobx-state-tree';
import { dateFormat } from '../utils/parsers';
import { http } from '../http';
import { Places } from './places';
import { States } from '../types/types';
import { links } from '../utils/pages';
import { pageNumber } from '../utils/const';

const Search = types
  .model('Search', {
    place: types.optional(types.string, ''),
    workplace: types.optional(types.string, ''),
  });

export const SearchDocs = Search
  .named('SearchDocs')
  .props({ title: types.optional(types.string, '') });
export interface SearchDocs extends Instance<typeof SearchDocs> {}

export const SearchPersons = Search
  .named('SearchPersons')
  .props({
    name: types.optional(types.string, ''),
    letter: types.optional(types.string, ''),
  });
export interface SearchPersons extends Instance<typeof SearchPersons> {}

export const Translations = types
  .model('Translations', {
    ru: types.optional(types.string, ''),
    fi: types.optional(types.string, ''),
  });
interface Translations extends Instance<typeof Translations> {}

export const findPlace = (placesStore: Places, id: number): Translations => {
  const place = placesStore.items && placesStore.items.find((placeItem) => placeItem.id === id);
  return {
    ru: place ? place.ru : '',
    fi: place ? place.fi : '',
  };
};

const fetchPlaces = (placesStore, placesIds): Promise<Array<Translations>> => new Promise((resolve) => {
  if (placesIds.length) {
    // const placesStore = getRoot(self).placesStore;
    if (!placesStore.items.length) {
      placesStore.fetch().then(
        () => {
          const places = placesIds.map((place) => findPlace(placesStore, place));
          resolve(places);
        }
      );
    } else {
      const places = placesIds.map((place) => findPlace(placesStore, place));
      resolve(places);
    }
  } else {
    resolve([]);
  }
});

export const Place = types
  .model('Place', {
    id: types.number,
    ru: types.string,
    fi: types.string,
    type: types.string,
  });

export const Page = types
  .model('Page', {
    endBack: types.maybeNull(types.string),
    endNumber: types.maybeNull(types.string),
    startBack: types.string,
    startNumber: types.string,
  });

const DocumentBase = types
  .model('DocumentBase', {
    id: types.maybeNull(types.number),
    title: types.maybe(types.string),
    dateEnd: types.maybeNull(types.Date),
    dateEstimate: types.maybe(types.boolean),
    dateStart: types.maybe(types.Date),
    dateType: types.maybe(types.string),
    comment: types.maybeNull(types.string),
    fileUrl: types.maybe(types.string),
    places: types.optional(types.array(Translations), []),
    workplaces: types.optional(types.array(Translations), []),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
  })
  .views((self) => ({
    get dateFormat(): string {
      return dateFormat({
        dateType: self.dateType,
        dateStart: self.dateStart,
        dateEnd: self.dateEnd,
      });
    },
  }))
  .actions((self) => {
    const setPlaces = (places: Array<Translations>): void => {
      self.places = places;
    };
    const setWorkPlaces = (places: Array<Translations>): void => {
      self.workplaces = places;
    };
    return { setWorkPlaces, setPlaces };
  });
interface DocumentBase extends Instance<typeof DocumentBase> {}

const responseData = (self, response, id): DocumentBase => {
  self.id = +id;
  self.dateEnd = response.dateEnd && new Date(response.dateEnd);
  self.dateEstimate = !!+response.dateEstimate;
  self.dateStart = new Date(response.dateStart);
  self.dateType = response.dateType;
  self.comment = response.comment;
  self.title = response.title;
  self.fileUrl = response.fileUrl;

  return self;
};

export const responseDocumentData = (self, response, id): Document => {
  self = responseData(self, response, id);
  self.editionNumber = response.editionNumber;
  self.editionYear = response.editionYear;
  self.fileNumber = response.fileNumber;
  self.fundNumber = response.fundNumber;
  self.pages = response.pages;
  self.periodicalsTitle = response.periodicalsTitle;
  self.seriesNumber = response.seriesNumber;

  return self;
};

export const responsePhotoData = (self, response, id): Photo => {
  self = responseData(self, response, id);
  self.author = (response.authorRus || response.authorFin) && {
    ru: response.authorRus,
    fi: response.authorFin,
  };

  return self;
};

const GalleryPhoto = types
  .model('GalleryPhoto', {
    id: types.identifierNumber,
    src: types.string,
    width: types.integer,
    height: types.integer,
    caption: types.string,
  });
export interface GalleryPhoto extends Instance<typeof GalleryPhoto> {}

interface Scan {
  id: number;
  url: string;
}

export const Document = DocumentBase
  .named('Document')
  .props({
    editionNumber: types.maybeNull(types.string),
    editionYear: types.maybeNull(types.string),
    fileNumber: types.maybeNull(types.string),
    fundNumber: types.maybeNull(types.string),
    pages: types.optional(types.array(Page), []),
    periodicalsTitle: types.maybeNull(types.string),
    seriesNumber: types.maybeNull(types.string),
    scansLoadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
    scans: types.optional(types.array(GalleryPhoto), []),
  })
  .views((self) => ({
    get isDocument(): boolean {
      return !!self.fundNumber;
    },
    get parsePages(): string {
      const pageStrings: string[] = [];
      self.pages.forEach((item) => {
        const pageStart = Boolean(+item.startBack) ? `${item.startNumber} об.` : item.startNumber;
        const pageEnd = Boolean(+item.endBack) ? `${item.endNumber} об.` : item.endNumber;
        pageStrings.push(!!item.endNumber ? `${pageStart}‑${pageEnd}` : pageStart);
      });
      return pageStrings.join(', ');
    },
    get references(): string {
      const pages = self.parsePages;
      return (self.fundNumber)
        ? `Ф. ${self.fundNumber}. Оп. ${self.seriesNumber}. Д. ${self.fileNumber}. Л. ${pages}`
        : `${self.periodicalsTitle}. ${self.editionYear}. № ${self.editionNumber}. С. ${pages}`;
    },
    get link(): string {
      return `${links.documents}/${self.id}`;
    },
    get getScans(): Array<GalleryPhoto> {
      return self.scans
        .map((item) => ({ ...item }))
        .sort((a, b) => a.id - b.id);
    },
    get getScansList(): Array<Scan> {
      const list: Scan[] = [];
      self.pages.forEach((item) => {
        const step = this.isDocument ? 0.5 : 1;
        const pageStart = +item.startNumber + (+item.startBack) * step;
        const pageEnd = item.endNumber ? +item.endNumber + (+item.endBack) * step : pageStart;
        for (let page = pageStart; page <= pageEnd; page += step) {
          const number = Math.floor(page);
          const offset = pageNumber.length - number.toString().length;
          const pageString = `${pageNumber.substr(0, offset)}${number}`;
          const pagePostfix = this.isDocument && (item.endNumber || +item.startBack)
            ? (Number.isInteger(page) ? 'A' : 'B')
            : '';
          list.push({
            id: page,
            url: `${self.fileUrl}-${pageString}${pagePostfix}.jpeg`,
          });
        }
      });
      return list;
    },
  }))
  .actions((self) => {
    const scanLoad = (url: string) => new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve({
        src: img.src,
        width: img.width,
        height: img.height,
      });
      img.onerror = (err) => reject(err);
    });
    const addScan = flow(function* addScan(scan: Scan) {
      try {
        const content = yield scanLoad(scan.url);
        self.scans.push({
          ...content,
          caption: self.title,
          id: scan.id,
        });
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        } else if (e instanceof Error) {
          throw new Error(e.message);
        } else {
          throw new Error('Error while adding scans');
        }
      }
    });
    const fillScans = flow(function* getPhotos() {
      self.scansLoadingState = States.Pending;
      try {
        yield Promise.all(self.getScansList.map((scan) => addScan(scan)));
        self.scansLoadingState = States.Done;
      } catch (e) {
        console.error('fillScans', e);
        self.scansLoadingState = self.scans.length ? States.Done : States.Error;
      }
    });
    const fillPlaces = flow(function* fillPlaces(placesIds, workPlacesIds) {
      try {
        yield Promise.all([
          fetchPlaces(getRoot(self).placesStore, placesIds),
          fetchPlaces(getRoot(self).workPlacesStore, workPlacesIds),
        ]).then((result) => {
          self.setPlaces(result[0]);
          self.setWorkPlaces(result[1]);
        });
      } catch (e) {
        console.error('Filling goals\' info didn\'t work', e);
      }
    });
    const fetch = flow(function* fetch(id: string, scans = false) {
      try {
        self.loadingState = States.Pending;
        const rr = yield http.get(`documents/${id}`);
        const response = rr.data;

        if (isAlive(self)) {
          self = responseDocumentData(self, response, id);
          self.loadingState = States.Done;

          yield fillPlaces(
            response.placesIds,
            response.workPlacesIds
          );

          if (scans) yield fillScans();
        }
      } catch (error) {
        self.loadingState = States.Error;
        console.error('Failed to fetch projects', error);
      }
    });
    return { fillScans, fetch, fillPlaces };
  });

export const Photo = DocumentBase
  .named('Photo')
  .props({
    author: types.maybeNull(Translations),
    shootPlace: types.maybeNull(types.string),
  })
  .views((self) => ({
    get link(): string {
      return `${links.photos}/${self.id}`;
    },
  }))
  .actions((self) => {
    const fillPlaces = flow(function* fillPlaces(placesIds, workPlacesIds) {
      try {
        yield Promise.all([
          fetchPlaces(getRoot(self).placesStore, placesIds),
          fetchPlaces(getRoot(self).workPlacesStore, workPlacesIds),
        ]).then((result) => {
          self.setPlaces(result[0]);
          self.setWorkPlaces(result[1]);
        });
      } catch (e) {
        console.error('Filling goals\' info didn\'t work', e);
      }
    });
    const fetch = flow(function* fetch(id: string) {
      try {
        self.loadingState = States.Pending;
        const rr = yield http.get(`photos/${id}`);
        const response = rr.data;

        if (isAlive(self)) {
          self = responsePhotoData(self, response, id);
          self.loadingState = States.Done;

          yield fillPlaces(
            response.placesIds,
            response.workPlacesIds
          );
        }
      } catch (error) {
        self.loadingState = States.Error;
        console.error('Failed to fetch projects', error);
      }
    });
    return { fetch, fillPlaces };
  });

export interface Document extends Instance<typeof Document> {
}

export interface Photo extends Instance<typeof Photo> {
}

const PersonBase = types
  .model('PersonBase', {
    id: types.maybeNull(types.number),
    surnameRus: types.maybeNull(types.string),
    nameRus: types.maybeNull(types.string),
    surnameFin: types.maybeNull(types.string),
    nameFin: types.maybeNull(types.string),
  });

export const PersonDocument = PersonBase
  .named('PersonDocument')
  .props({
    homeland: types.maybeNull(types.string),
    source: types.optional(Document, {}),
    places: types.optional(types.array(types.number), []),
    workplaces: types.optional(types.array(types.number), []),
    personPages: types.optional(types.array(Page), []),
  })
  .views((self) => {
    const views = {
      get placesStore(): Places {
        return getRoot(self).placesStore;
      },
      get workPlacesStore(): Places {
        return getRoot(self).workPlacesStore;
      },
      get placesNames(): Array<Translations> {
        if (!!views.placesStore && views.placesStore.loadingState === States.Done) {
          return self.places.map((place) => findPlace(views.placesStore, place));
        }
        return self.places.map(() => ({
          ru: '',
          fi: '',
        }));
      },
      get workplacesNames(): Array<Translations> {
        if (!!views.workPlacesStore && views.workPlacesStore.loadingState === States.Done) {
          return self.workplaces.map((place) => findPlace(views.workPlacesStore, place));
        }
        return self.workplaces.map(() => ({
          ru: '',
          fi: '',
        }));
      },
      get findIndex() {
        const step = self.source.isDocument ? 0.5 : 1;
        const id = +self.personPages[0].startNumber + (+self.personPages[0].startBack) * step;
        return self.source.getScans.findIndex((scan) => scan.id === id);
      },
    };
    return views;
  });

export interface PersonDocument extends Instance<typeof PersonDocument> {}

export const PersonPhoto = PersonBase
  .named('PersonPhoto')
  .props({ source: types.optional(Photo, {}) });
export interface PersonPhoto extends Instance<typeof PersonPhoto> {}
