import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, Row, Container } from '../components-ui';

export const Error: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <p>{t('Произошла ошибка.')}</p>
        <p>{t('Попробуйте обновить страницу.')}</p>
      </Row>
    </Container>
  );
};
