import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import { withRouter, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import Main from './Main';
import { RootStore } from '../rootStore';

interface Props {
  store: RootStore;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  &.noscroll {
    overflow: hidden;
  }
`;

const Layout: FunctionComponent<Props> = ({ store }) => {
  const [openedSideNav, setSideNav] = useState(false);
  const history = useHistory();

  history.listen(() => { setSideNav(false); });

  const toggleSideNav = (): void => {
    setSideNav(!openedSideNav);
  };

  return (
    <Wrapper className={openedSideNav ? 'noscroll' : ''}>
      <Header openedSideNav={openedSideNav} toggleSideNav={toggleSideNav} />
      <Main store={store} />
      <Footer />
    </Wrapper>
  );
};

export default withRouter(observer(Layout));
