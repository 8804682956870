import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from './Settings';

interface Props {
  url?: string;
  label?: string;
}

const Image = styled.div`
  width: 120px;
  min-width: 120px;
  height: 120px;
  margin-right: 36px;
  object-fit: cover;
`;

const ImageCover = styled(Image)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  color: ${colors.gray300};
  position: relative;
  padding: 16px;
`;

const IconSet = styled.span`
  width: 40px;
  height: 60px;

  svg {
    height: auto;
    
    &:first-child {
      width: 30px;
    }

    &:last-child {
      width: inherit;
    }
  }
`;

const IconLabel = styled.span`
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: #a0a0a0;
`;

export const Cover: FunctionComponent<Props> = ({ url, label }) => {
  const { t } = useTranslation();
  const [broken, setBroken] = useState(false);

  return (
    <>
      {!!url && !broken
        ? <Image as="img" className="mobile-hide" src={url} onError={() => setBroken(true)} />
        : (
          <ImageCover className="mobile-hide">
            <IconSet className="fa-layers fa-fw">
              <FontAwesomeIcon icon={['far', 'file-alt']} />
              <FontAwesomeIcon icon={['fas', 'slash']} />
            </IconSet>
            <IconLabel>{t(label || 'Скан отсутствует')}</IconLabel>
          </ImageCover>
        )}
    </>
  );
};
