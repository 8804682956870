import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fiResources from './labels/fi.json';
import ruResources from './labels/ru.json';
import { defaultLanguage } from './utils/const';

const resources = Object.assign(fiResources, ruResources);

const detectorOptions = {
  order: ['localStorage', 'htmlTag'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: detectorOptions,
    fallbackLng: defaultLanguage,
    whitelist: ['ru', 'fi'],
    resources,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: { escapeValue: false },
  });

export default i18n;
