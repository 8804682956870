import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, Row } from '../components-ui';

const RowText = styled(Row)`
  margin-bottom: 32px;

  &:not(:first-child, :last-child) {
    margin-top: 32px;
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    padding: 4px 0;

    span {
      display: block;
      padding: 8px 0;

      a {
        text-decoration: underline;
      }
    }
  }
`;

const AbbreviationsList: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <RowText><h2>{t('Перечень сокращений')}</h2></RowText>
      <RowText>
        <h3>{t('АКССР-название')}</h3>
        <p>{t('АКССР-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Амторг-название')}</h3>
        <p>{t('Амторг-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('ВКП-название')}</h3>
        <p>{t('ВКП-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Интурист-название')}</h3>
        <p>{t('Интурист-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Горсовет-название')}</h3>
        <p>{t('Горсовет-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('ГПУ-название')}</h3>
        <p>{t('ГПУ-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Инснаб-название')}</h3>
        <p>{t('Инснаб-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('КарЦИК-название')}</h3>
        <p>{t('КарЦИК-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Лесокомбинат-название')}</h3>
        <p>{t('Лесокомбинат-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Леспромхоз-название')}</h3>
        <p>{t('Леспромхоз-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Лесопункт-название')}</h3>
        <p>{t('Лесопункт-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('МТС-название')}</h3>
        <p>{t('МТС-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Наркомат-название')}</h3>
        <p>{t('Наркомат-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Наркомтруд-название')}</h3>
        <p>{t('Наркомтруд-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Обком-название')}</h3>
        <p>{t('Обком-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Осоавиахим-название')}</h3>
        <p>{t('Осоавиахим-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Промкомбинат-название')}</h3>
        <p>{t('Промкомбинат-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Райсовет-название')}</h3>
        <p>{t('Райсовет-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('РСФСР-название')}</h3>
        <p>{t('РСФСР-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Совторг-название')}</h3>
        <p>{t('Совторг-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('СНК-название')}</h3>
        <p>{t('СНК-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('СССР-название')}</h3>
        <p>{t('СССР-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('Торгсин-название')}</h3>
        <p>{t('Торгсин-определение')}</p>
      </RowText>
      <RowText>
        <h3>{t('ЦСНХ-название')}</h3>
        <p>{t('ЦСНХ-определение')}</p>
      </RowText>
    </Container>
  );
};

export default AbbreviationsList;
