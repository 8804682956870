import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Carousel, { Modal, ModalGateway } from 'react-images';

type FullscreenProps = { isFullscreen: boolean };

const Counter = styled.span`
  white-space: nowrap;
`;

export const FooterCount = (props) => {
  const { t } = useTranslation();
  const { currentIndex, views } = props;
  const activeView = currentIndex + 1;
  const totalViews = views.length;

  if (!activeView || !totalViews) return null;

  return (
    <Counter>
      {activeView} {t('из')} {totalViews}
    </Counter>
  );
};

interface Props {
  viewerIsOpen: boolean;
  onClose(): void;
  currentIndex: number;
  views: Array<{src: string; caption?: string}>;
}

export const CarouselCustom = (props: Props) => {
  const { viewerIsOpen, onClose, currentIndex, views } = props;
  const { t } = useTranslation();

  const formatters = {
    getAltText: ({ data, index }): string => {
      if (data.caption) return data.caption;
      return `${t('Скан')}${index + 1}`;
    },
    getNextLabel: (): string => t('Следующий'),
    getPrevLabel: (): string => t('Предыдущий'),
    getNextTitle: (): string => t('Следующий'),
    getPrevTitle: (): string => t('Предыдущий'),
    getCloseLabel: (): string => t('Закрыть'),
    getFullscreenLabel: ({ isFullscreen }: FullscreenProps): string => (isFullscreen ? t('Выход из полноэкранного режима') : t('Во весь экран')),
  };

  return (
    <ModalGateway>
      {viewerIsOpen ? (
        <Modal onClose={onClose}>
          <Carousel
            currentIndex={currentIndex}
            views={views}
            components={{ FooterCount }}
            formatters={formatters}
          />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};
