import styled from 'styled-components';
import { Opacity as OpacityProp } from '../types/types';
import { breakpoints } from './Settings';

export const FlexWrapper = styled.div`
  display: flex;
`;

export const AdaptiveWrapper = styled(FlexWrapper)`
  flex-wrap: wrap;
`;

export const PageWrapper = styled.div`
  padding: 48px 96px;

  @media (max-width: ${breakpoints.xs}) {
    h2 {
      font-size: 20px;
    }
  }

  @media (max-width: ${breakpoints.s}) {
    padding: 32px;
    h2 {
      font-size: 22px;
    }
  }

  h2 {
    padding-bottom: 24px;
    max-width: 1000px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;

  img {
    width: 100%;
    min-width: 350px;

    @media (max-width: 430px) {
      min-width: unset;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px 96px;

  @media (max-width: ${breakpoints.m}) {
    padding: 32px;
  }

  @media (max-width: ${breakpoints.xs}) {
    padding: 24px;
  }
`;

export const Row = styled.div`
  p {
    text-align: left;
    line-height: 24px;
  }
`;

export const ItalicPar = styled.p`
  font-style: italic;
`;

interface Props {
  label: string;
}

export const SemiTitle = styled.h3`
  padding: 24px 0;
`;

export const TableWrapper = styled.div`
  padding: 16px 0;
`;

export const Opacity = styled.div`
  opacity: ${(props: OpacityProp): string => (props.semi ? '0.5' : '1')};
`;

export const OverflowWrapper = styled.div`
  overflow-x: auto;

  @media (max-width: ${breakpoints.s}) {
    margin: 0 -24px;
  }
`;
