import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, breakpoints } from './Settings';
import { AdaptiveWrapper } from './Wrappers';

export const IconLink = styled(Link)`
  display: block;
  margin-bottom: 24px;
  
  svg {
    color: ${colors.primary};
    margin-right: 4px;
  }
`;

export const ItemWrapper = styled(AdaptiveWrapper)`
  padding-top: 16px;
  
  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
  }
`;

export const ItemInfo = styled.div`
  flex: 1;
  p {
    padding: 4px 0;
    line-height: 26px;

    svg {
      margin-right: 12px;
      color: ${colors.primary};
    }
  }

  @media (min-width: ${breakpoints.m}) {
    border-right: 2px solid ${colors.primary};
    min-width: 350px;
    padding-right: 48px;
  }
`;

export const ItemPlace = styled.span`
  &:not(:nth-child(2)) {
    display: block;
    padding-left: 32px;
  }
`;

export const ItemImages = styled.div`
  flex: 1;
  width: 100%;
  text-align: center;
  padding-left: 48px;

  img {
    max-height: 300px;
    width: auto;
  }

  @media (max-width: ${breakpoints.m}) {
    padding-left: 0;
    padding-top: 24px;
  }
`;
