import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Search as SearchComponent } from '../views/Search';
import { NoMatch } from './NoMatch';
import { links } from '../utils/pages';

const Search: FunctionComponent = () => (
  <Switch>
    <Route exact path={links.search} component={SearchComponent} />
    <Route exact path={`${links.search}/:place`} component={SearchComponent} />
    <Route component={NoMatch} />
  </Switch>
);

export default Search;
