import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faFileAlt, faImage } from '@fortawesome/free-regular-svg-icons';
import {
  faArchive,
  faNewspaper,
  faCalendar as fasCalendar,
  faMapMarkerAlt,
  faBuilding,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSearch,
  faCircleNotch,
  faTimes,
  faMapSigns,
  faArrowCircleLeft,
  faBars,
  faSlash
} from '@fortawesome/free-solid-svg-icons';
import { RootStore } from './rootStore';

import Layout from './layouts/Layout';
import GlobalStyles from './styles';

library.add(
  faArchive,
  faNewspaper,
  faCalendar,
  fasCalendar,
  faMapMarkerAlt,
  faBuilding,
  faAngleRight,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSearch,
  faCircleNotch,
  faTimes,
  faMapSigns,
  faFileAlt,
  faImage,
  faArrowCircleLeft,
  faBars,
  faSlash
);

interface Props {
  store: RootStore;
}

const App: FunctionComponent<Props> = ({ store }) => (
  <>
    <GlobalStyles />
    <Layout store={store} />
  </>
);

export default withRouter(observer(App));
