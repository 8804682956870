import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, Row } from '../components-ui';

const RowText = styled(Row)`
  margin-bottom: 32px;

  &:not(:first-child, :last-child) {
    margin-top: 32px;
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    padding: 4px 0;

    span {
      display: block;
      padding: 8px 0;

      a {
        text-decoration: underline;
      }
    }
  }
`;

const Literature: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <RowText><h2>{t('Основные публикации о североамериканских финнах в Карелии')}</h2></RowText>
      <RowText>
        <h3>{t('Работы 1930-х годов')}</h3>
        <p>
          <span>Anttonen E. Puumassan valmistus. Petroskoi: Kirja, 1933. 96 s.</span>
          <span>Anttonen E., Kanervo V. Sulfiitti- ja sulfaattiselluloosan valmistus. Petroskoi: Kirja, 1933. 199 s.</span>
          <span>Bekrenev Nik. Kanadalaiset metsätöissä Neuvosto-Karjalassa. Karjalan metsätutkimusasema. Petroskoi: Kirja, 1932. 33 s.</span>
          <span>Boiko P.A. Brigadit ja jatsheikat metsätöissä Karjalassa. Petroskoi: Kirja, 1932. 8 s.</span>
          <span>Boiko P.A. Metsätyöläisen opas. Apukirja tuotannollisia kerhoja varten. Petroskoi: Kirja, 1934. 123 s.</span>
          <span>Ford-auton ohjaus ja hoito. Malli ”A” ja ”AA”. Petroskoi: Kirja, 1933. 51 s.</span>
          <span>Fordson-traktori. Varaosaluettelo. Petroskoi: Kirja, 1932. 65 s.</span>
          <span>Forsberg K. Sahanterien asetus ja huolto. Petroskoi: Kirja, 1932. 64 s.</span>
          <span>Gorjatshko I.S. Lakimääräykset ja säännökset turvallisuustekniikasta metsätöissä, uitossa, ratapölkkysahauksessa ja koneissa. L.: Kirja, 1934. 103 s.</span>
          <span>Gorjatshko I.S. Ratsionalisointiminimi Karjalan metsätöissä. Petroskoi: Kirja, 1934. 36 s.</span>
          <span>Gorjatshko I.S. Karjalan metsätöiden ratsionalisointiminimi. Petroskoi: Kirja, 1935. 122 s.</span>
          <span>Johansson O. Kontupohja – Iskutyö. Kontupohjan työkollektiivin vastuksista ja voitoista. L.: Kirja, 1932. 127 s.</span>
          <span>Traktoristin käsikirja. Petroskoi: Kirja, 1932. 80 s.</span>
          <span>Lobov S. Puutavarateollisuuden kysymykset stahanovilaisen liikkeen yhteydessä.  Selostus NKP (b):n KK:n pleenumissa joulukuun 22 pv:nä v. 1935. Petroskoi: Kirja, 1936. 41 s.</span>
          <span>Luoto L. Lakeuksien Aunus. Petroskoi: Kirja, 1936. 150 s.</span>
          <span>Nikitin Y. Metsätyömiehen opastukseksi. Hakkuu, ajo ja työvoiman käyttö. Petroskoi: Kirja, 1933. 53 s.</span>
          <span>Neuvosto-Karjalan 15-vuotiselta taipaleelta 1920-1935. New York: Finnish Federation Inc., 1935. 98 s.</span>
          <span>Rautiainen E. Neuvostomaata rakentamassa.  Amerikan suomalaiset sosialistisessa rakennustyössä Neuvosto-Karjalassa. Petroskoi: Kirja, 1933. 96 s.</span>
          <span>Salo A. Amerikkalaiset metsätyömetodit ja työvälineet. Petroskoi: Kirja, 1934. 38 s.</span>
          <span>Suikkanen V.M. Rakennustyökunta sementti: amerikan suomalaiset työläiset sosialistisen rakennustyön rintamalla // Työläisen ja kollektivistin kalenteri vuodelle 1932. L.: Sosialististen Neuvostotasavaltojen Liitto, 1932. S. 89-92.</span>
          <span>Tenhunen M. Amerikan suomalaiset työläiset ja Neuvostoliiton sosialistinen rakennustyö // Työläisen ja kollektivistin kalenteri vuodelle 1932. L.: Sosialististen Neuvostotasavaltojen Liitto, 1932. S. 52-66.</span>
          <span>Timonen S.M. Kehäsahojen työskentelyn vikojen syyt ja keinot niiden poistamiseksi. Petroskoi: Kirja, 1934. 85 s.</span>
          <span>Tonkel J. Brigadit ja jatsheikat metsätöissä Karjalassa. Petroskoi: Kirja, 1932. 29 s.</span>
          <span>Tonkell I.  Jännesaha. L.: Kirja, 1933. 15 s.</span>
          <span>Tonkel I. Miten puutavaran kuljetusta tehostetaan. L.: Kirja, 1933. 17 s.</span>
          <span>Uittomiehen käsikirja. A. Oksalan y.m. pohjalta toim. ja Karjalan oloihin mukaillut Matti Anttonen. L.: Kirja, 1931. 122 s.</span>
          <span>Virta O. Puu ja sen jalostus. L.: Kirja, 1932. 136 s.</span>
        </p>
        <p>
          <span>Бекренев Н. Канадские лесорубы в Карелии. Петрозаводск: Кирья, 1932. 33с.</span>
          <span>Бекренев Н. Канадские лесорубы на лесозаготовках в Карелии / Карел.лесная опытная станция. Петрозаводск: Кирья, 1933. 34с.</span>
          <span>Горячко И.С. Рационализаторский минимум на лесозаготовках Карелии. Петрозаводск: Кирья, 1935. 140 с.</span>
          <span>Петров И.А. Коммуна «Сяде». Петрозаводск: Б.и., 1930. 35 с.</span>
          <span>Тонкель И.И. Канадские лесорубы в советской Карелии: техника, организация труда, нормы выработки, быт. [Москва]: Государственное лесное техническое издательство, 1934. 151 с.</span>
          <span>Тонкель И. Бригады и ячейки на лесозаготовках в Карелии. Петрозаводск: Кирья, 1932. 33 с.</span>
        </p>
      </RowText>
      <RowText>
        <h3>{t('Воспоминания')}</h3>
        <p>
          <span>Boucht C. Onnea etsimässä. Punaisesta Karjalasta Kaukoitään. Juva: WSOY, 1973. 307 s.</span>
          <span>Boucht C. Karjala kutsuu. Helsinki: Kirjayhtymä, 1988.</span>
          <span>Haapaniemi E. Muistelma // Lännestä itään. 1994. № 4. S. 12-18.</span>
          <span>Hokkanen Sylvia and Laurence with Anita Middleton. Karelia: A Finnish-American Couple in Stalin’s Russia, 1934 - 1941. St. Cloud: North Star Press, 1991. 160 р.</span>
          <span>Isotalo A. Kirje // Maitten ja merten takaa. Vuosisata suomalaisia siirtolaiskirjeitä. Turku: Turun Historiallinen Arkisto, 1985. S. 236 - 240.</span>
          <span>Komulainen E. A Grave in Karelia. New-York: Braun Brumfield, 1995. 128 p.</span>
          <span>Koponen O-M. Tavallisen ihmisen tarina. Vaasa: Ykkös-Offset OY, 2002. 221 s.</span>
          <span>Kuusikko W. Kirje sukulaisilleen Kemin seudulle Kontupohjasta // Maitten ja merten takaa. Vuosisata suomalaisia siirtolaiskirjeitä. Turku: Turun Historiallinen Arkisto, 1985. S. 244-250.</span>
          <span>Linkström V. Kirje sisarelleen Yhdysvalloihin Kontupohjasta // Maitten ja merten takaa. Vuosisata suomalaisia siirtolaiskirjeitä. Turku: Turun Historiallinen Arkisto, 1985. S. 241.</span>
          <span>Miettinen H., Joganson K. Petettyjen toiveiden maa. Saarijärvi: Gummerus kirjapaino OY, 2001. 198 s.</span>
          <span>Ranta K. Arpi korvassa ja sydämessä. Helsinki: WSOY, 2000. 456 s.</span>
          <span>Sevander M. They took my father: A Story of Idealism and Betrayal. Duluth: Pfeiffer-Hamilton, 1991. 190 p.</span>
          <span>Soneppi J. Kirje // Maitten ja merten takaa. Vuosisata suomalaisia siirtolaiskirjeitä. Turku: Turun Historiallinen Arkisto, 1985. S. 231.</span>
          <span>Suomela V. Kuusi kuukautta Karjalassa: mitä siirtolainen näki ja koki Neuvosto-Karjalassa. Sudbury Ont.: Vapaa Sana, 1943. 64 s.</span>
          <span>Tuomi K. Isänmaattoman tarina. Amerikansuomalaisen vakoojan muistelmat. Porvoo: WSOY, 1984. 190 s.</span>
          <span>Tuomi K. The Karelian Fever of the Early 1930s: A Personal Memoir // Finnish Americana. 1980. Vol. 3. P. 61–75.</span>
          <span>Zlobina V. Heidän ihanteensa murskattiin.  Tyttären tarina Säde-kommuunasta Neuvosto-Karjalassa. Turku: Siirtolaisuusinstituutti, 2017. 116 s.</span>
        </p>
        <p>
          <span>Ерохин С. П. Перевалка // На фронте мирного труда. Воспоминания участников социалистического строительства в Карелии. 1920-1940. Петрозаводск: Карелия, 1976. С. 73- 77.</span>
          <span>Злобина В.Э. Как погасили «Луч» // Север. 1990. № 7. С. 157-160.</span>
          <span>Злобина В.Э., Сийканен Л.Г. Коммунары // На фронте мирного труда. Воспоминания участников социалистического строительства в Карелии. 1920–1940. Петрозаводск: Карелия, 1976. С. 153–157.</span>
          <span>Злобина В. Растоптанные идеалы. Канадская девочка за железным занавесом. Хельсинки: NordPrint Oy, 2019. 102 с.</span>
          <span>Кивипелто Л. Э. Наш мастерский участок. Петрозаводск: Госиздат Карело-Финской ССР, 1954. 40 с.</span>
          <span>Копонен М. В. Женщины на лесозаготовках // На фронте мирного труда. Воспоминания участников социалистического строительства в Карелии. 1920-1940. Петрозаводск: Карелия, 1976. С. 80-84.</span>
          <span>Нурминен В. Г. На Лыжной фабрике // На фронте мирного труда. Воспоминания участников социалистического строительства в Карелии. 1920-1940. Петрозаводск: Карелия, 1976. С.43- 46.</span>
          <span>Севандер М., Хертцель Л. Они забрали у меня отца: Американские финны в сталинской России. Петрозаводск: Изд-во КГПА, 2010. 223 с.</span>
          <span>Сундфорс X. X. На целлюлозном заводе // На фронте мирного труда. Воспоминания участников социалистического строительства в Карелии. 1920-1940. Петрозаводск: Карелия, 1976.С.22-25.</span>
          <span>Туоми Э. В. Памятник Ильичу // На фронте мирного труда. Воспоминания участников социалистического строительства в Карелии. 1920-1940. Петрозаводск: Карелия, 1976. С. 49- 52.</span>
          <span>Юлен К. К. О строительстве первого водопровода в Петрозаводске // На фронте мирного труда. Воспоминания участников социалистического строительства в Карелии. 1920-1940. Петрозаводск: Карелия, 1976. С. 52-55.</span>
        </p>
      </RowText>
      <RowText>
        <h3>{t('Интервью')}</h3>
        <p>
          <span>Mykkänen Y. Yrjö Mykkäsen on ollut pakko mykistyä / haast. ja runoillut Paavo Voutilainen // Carelia. 1995. № 11. S. 112-121.</span>
        </p>
        <p>
          <span>Интервью с Роем Нисканеном // Устная история в Карелии. Вып. 4. Карелия и Беларусь: повседневная жизнь и культурные практики населения в 1930–1950-е гг. Петрозаводск: Изд-во ПетрГУ, 2008. С. 335-358.</span>
          <span>Устная история в Карелии. Вып. 2. Североамериканские финны в Советской Карелии 1930-х гг. / Под ред. И. Р. Такала и А. В. Голубева. Петрозаводск: Изд-во ПетрГУ, 2007. С. 52-158.</span>
        </p>
      </RowText>
      <RowText>
        <h3>{t('Исследования')}</h3>
        <p>
          <span>Ahola D. The Karelian Fever Episode of the 1930's // Finnish Americana. 1982-83. Vol. 5. P. 4 - 7.</span>
          <span>Autio S. Suunnitelmatalous Neuvosto-Karjalassa 1928–1941. Helsinki: SKS, 2002. 398 s.</span>
          <span>Efremkin E. «Karelian project» or « Karelian fever»? Orders from above, reaction from below: conflicting interests in Kremlin, Karelia and Canada // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 55-82.</span>
          <span>Efremkin E. Recruitment in North America: An Analysis of Emigrants to Soviet Karelia, 1931–1934 // Journal of Finnish Studies. 2011. Vol. 15. Iss. 1–2. P. 101–123.</span>
          <span>Gelb M. “Karelian Fever”: The Finnish Immigrant Community During Stalin’s Purges // Europe-Asia Studies. 1993. Vol. 45, no. 6. P. 1091-1116.</span>
          <span>Golubev A., Takala I. The Search for a Socialist El Dorado: Finnish Immigration to Soviet Karelia from the United States and Canada in the 1930s. East Lansing, MI: Michigan State University Press, 2014. 236 p.</span>
          <span>Harpelle R., Lindström V. and Pogorelskin A. (eds.). Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004. 226 p. (Journal of Finnish Studies, vol. 8, no. 1).</span>
          <span>Hovi R-L. Amerikansuomalaisten osuuskunnat Neuvosto-Karjalassa 1920-luvun alkupuolella amerikansuomalaisten ja neuvostokarjalaisten sanomalehtien valossa. Turku: Turun yliopisto, 1969. 108 s.</span>
          <span>Hudelson R., Sevander M. A Relapse of Karelian Fever // Siirtolaisuus / Migration. 2000. № 2. S. 31-34.</span>
          <span>Jääskeläinen M., Bystriakova N. American and Canadian Finns and the development of the forest sector of Russian Karelia in the 1930s // Альманах североевропейских и балтийских исследований. Выпуск 3, 2018. [Электронный ресурс.] URL: <a href="http://nbsr.petrsu.ru/journal/article.php?id=1070" target="_blank">http://nbsr.petrsu.ru/journal/article.php?id=1070</a></span>
          <span>Kamppinen, Ossi: Palkkana pelko ja kuolema. Neuvosto-Karjalan suomalaiset rakentajat. Helsinki: Docendo, 2019. 314 s.</span>
          <span>Kangaspuro M. The Soviet Depression and Finnish Immigrants in Soviet Karelia // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004.  P. 132-140.</span>
          <span>Kangaspuro M., Saramo S., eds. Victims and Survivors of Karelia (Journal of Finnish Studies. 2011. Vol. 15, No. 1–2. Special double issue). 180 p.</span>
          <span>Karni M. Finnish Americans in Soviet Karelia, 1931-1991: An Update. // Pitkät jäljet Historioita kahdelta maantereelta. Turku: Painosalama OY, 1999. P. 108-119.</span>
          <span>Karni M.G. Kaarlo Tuomi: Man Without a Country - the Tale of a Finnish American Russian Spy // Entering Multiculturalism Finnish Experience Abroad: Papers from FinnForum VI. Turku: Institute of Migration, 2002.</span>
          <span>Kero R. Emigration of Finns from North America to Soviet Karelia in the Early 1930's. // The Finnish Experience in the Western Great Lakes Region: New Perspectives. Turku: Institute for Migration, 1975. P. 212–221.</span>
          <span>Kero R. The Canadian Finns in Soviet Karelia in the 1930’s. // Finnish Diaspora I: Canada, South America, Africa, Australia, and Sweden. Papers of the Finn Forum II. Toronto, 1979. P. 203–213.</span>
          <span>Kero R. The Role of Finnish Settlers from North America in the Nationality Question in Soviet Karelia in the 1930's // Scandinavian Journal of History. 1981. № 6. P. 17-29.</span>
          <span>Kero R. The Tragedy of Jonas Harju of Hiilisuo Commune, Soviet Karelia, 1933-1936 // Finnish Americana. 1982. Vol. 5. P. 8 -11.</span>
          <span>Kero R. Neuvosto-Karjalaa rakentamassa: Pohjois-Amerikan suomalaiset tekniikan tuojuina 1930-luvun Neuvosto-Karjalassa // Historiallisin Tutkimuksia. 1983. № 122. S. 34-57.</span>
          <span>Kero R. Neuvosto-Karjalaa rakentamassa. Pohjois-Amerikan suomalaiset tekniikan tuojina 1930-luvun Neuvosto-Karjalassa. Helsinki: SHS, 1983. 231 s.</span>
          <span>Kero R. Suuren Länteen: Siirtolaisuus Suomesta Yhdysvaltoihin ja Kanadaan. Turku: Siirtolaisuusinstituutti, 1996.</span>
          <span>Kero R. Suomalaisina Pohjois-Amerikassa. Siirtolaiselämää Yhdysvalloissa ja Kanadassa. Turku: Siirtolaisuusinstituutti, 1997.</span>
          <span>Kivisto P. Immigrant Socialists in the United States: The Case of Finns and the Left. Rutherford: Fairleigh Dickinson University Press, 1984.</span>
          <span>Kivisto P. The Decline of the Finnish American Left, 1925–1945 // International Migration Review. 1983. Vol. 17, no. 1. P. 68–74.</span>
          <span>Kivisto P., Roinila M. Reaction to departure: the finnish American community responds to « Karelian fever» // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 17-38.</span>
          <span>Kostiainen A. The Forging of Finnish-American Communism, 1917–1924: A Study of Ethnic Radicalism. Turku: Turun yliopisto, 1978. 225 p.</span>
          <span>Kostiainen A. Santeri Nuorteva. Kansainvälinen suomalainen. Helsinki: SKS, 1983. 224 p.</span>
          <span>Kostiainen A. Neuvosto-Karjala ja ‘Kaukaisen idän aromailta’ — Suomalaiskirjeitä Venäjältä ja Neuvostoliitosta // Maitten ja merten takaa: Vuosisata suomalaisia siirtolaiskirjeitä / Toim. E. Kuparinen. Turku: Turun Historiallinen Yhdistys, 1986. S. 209-261.</span>
          <span>Lahti-Argutina E. Documented Evidence of the Fate of Finnish Canadians in Soviet Karelia // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004.  P. 118-131.</span>
          <span>Lahti-Argutina E. Olimme joukko vieras vaan: Venäjänsuomalaiset vainonuhrit Neuvostoliitossa 1930-luvun alusta 1950-luvun alkuun. Turku: Siirtolaisuusinstituutti, 2001. 652 s.</span>
          <span>Laitinen N. Mayme Sevander ja hänen kirjansa // Carelia. 2010. № 12. S. 41-49.</span>
          <span>Lindström V. Martta Laitinen’s Relentless Work for Socialism in Finland, Canada, and Soviet Karelia // Canadian Woman Studies/Les cahiers de la femme. 1989. Vol. 10, no. 4. P. 68–70.</span>
          <span>Lindström V., Vähämäki B. Ethnicity twice removed. North American Finns in Soviet Karelia // Finnish Americana. 1992. Vol. 9. S. 14-20.</span>
          <span>Lindström V. «Heaven or hell on Earth?» soviet Karelia΄s propaganda war of 1934-1935 in the finnish Canadian press // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 83-104.</span>
          <span>Middleton A. Karelian Fever: Interviewes with Survivors // Journal of Finnish Studies. 1997. Vol. 1. № 3. P. 179 - 182.</span>
          <span>Miettinen H. and Warkentin R. Memories of the Depression in North America among Finnish North Americans in the Soviet Union // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004.  P. 201-215.</span>
          <span>Niemelä J. Lännenlokari ja tiskari jazzin ja laman pyörteissä: [lyhyt katsaus amerikansuomalaisten laulujen kulttuuri-identiteetti kuvaan 1920- ja 1930-luvuilla] // Carelia. 1996. № 8. S. 116–124.</span>
          <span>Paaso V. Amerikansuomalaiset Mayme Sevanderin tutkimuksissa // Carelia. 2001. № 8. S. 87–90.</span>
          <span>Platt W. C. Background on « Karelian fever», as viewed from Communisti Party USAS records // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 39-54.</span>
          <span>Pogorelskin A. New perspectives on Karelian Fever: The Recruitment of North American Finns to Karelia in the Early 1930s // Journal of Finnish Studies. 1997. Vol. 1. № 3. P. 165 - 178.</span>
          <span>Pogorelskin A. Why Karelian Fever? // Siirtolaisuus / Migration. 2000. № 1. S. 25 - 26.</span>
          <span>Pogorelskin A. Communism and the Co-Ops: Recruiting and Financing the Finnish-American Migration to Karelia // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004.  P.28-47.</span>
          <span>Pogorelskin A. Pipeline Accident on Lake Onega: A Study of Ethnic Conflict in Soviet Karelia, 1934 // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004.  P. 176-187.</span>
          <span>Pogorelskin A. Edvard Gylling and the Origins of Karelian Fever // The Dividing Line: Borders and National Peripheries. Helsinki: Renvall Institute Publications No. 9, University of Helsinki Press, 1997. P. 261-271.</span>
          <span>Pogorelskin A. Matti Tenhunen and the recruitment of north American finns to Karelia: new questions // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 139-151.</span>
          <span>Prosutinski S. Viljo Vesa – urheilija ka näyttelijä // Carelia. 1998. № 6. S. 143–148.</span>
          <span>Saarela T. (toim.). Talonpoikainen sosialisti – Santeri Mäkelä poliittisena toimijana ja kirjailijana. Helsinki: Työväen historian ja perinteen tutkimuksen seura, 1997. 139 s. (Väki Voimakas 7).</span>
          <span>Saramo S. Piecing together immigrant lives: an analysis of personal letters written by north Americans finns in soviet Karelia // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 170-189.</span>
          <span>Saramo S. Life Moving Forward: Soviet Karelia in the Letters & Memoirs of Finnish North Americans: PhD Dissertation. York University, 2014.</span>
          <span>Saramo S. “I have such sad news”: Loss in Finnish North American Letters // European Journal of Life Writing. 2018, Vol VII. P. 53–71.</span>
          <span>Sevander M. Red Exodus: Finnish-American Emigration to Russia. Duluth, MN: OSCAT, 1993. 232 p.</span>
          <span>Sevander M. Of Soviet Bondage. Sequel to "Red Exodus". Duluth, MN: OSCAT, 1996. 157 p.</span>
          <span>Sevander M. Vaeltajat. Turku: Siirtolaisuusinstituutti, 2000. 249 p.</span>
          <span>Sevander S. Soviet Karelia in 1940s and 1959s as viewed by north american finns // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 236-255.</span>
          <span>Simakin V. He eivät odottaneet lahjoja elämältä, vaan rakensivat sitä itse [Nivan perhe] // Carelia. 1999. № 12. S. 97–102</span>
          <span>Suutari P. Going beyond the Border: National Cultural Policy and the Development of Musical Life in Soviet Karelia, 1920–1940 // Soviet Music and Society under Lenin and Stalin: The Baton and Sickle / Ed. N. Edmunds. London: Routledge, 2004. P. 163–180.</span>
          <span>Takala I. Eldoradoa etsimässä. Tarina ennen sotia Neuvosto-Karjalaan valtavesien takaa saapuneista amerikansuomalaista // Carelia. 1993. № 3. S.4-25.</span>
          <span>Takala I. Amerikansuomalaiset Neuvosto-Karjalaa rakentamassa // Verso. 1993. № 5-6. S.22-25.</span>
          <span>Takala I. Kansallisuusoperaatiot Karjalassa // Timo Vihavainen ja Irina Takala (toim.). Yhtä suurta perhettä. Bolshevikkien kansallisuuspolitiikka Luoteis-Venäjällä 1920 -1950 -luvuilla. Helsinki, 2000, s. 181-232.</span>
          <span>Takala I. Out of the Frying Pan into the Fire. North American Finns in Soviet Karelia // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004.  P. 105-117.</span>
          <span>Takala I. Sel-fIdentification of Finnish Immigrants in the Soviet Karelia in the 1920s – 1930s // Regional Northern Identity: from Past to Future. Petrozavodsk, 2006. P. 66-67.</span>
          <span>Takala I. Finnish immigrants in the Soviet Karelia in 1920s and 1930s — the study of ethnic identities // Challenges of Globalisation and Regionalisation. Luleå: Luleå University of Technology, 2007. P. 57–69.</span>
          <span>Takala, I. North American finns as viewed by the population of soviet Karelia in 1930s // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 190-212.</span>
          <span>Takala I. Golubev A. Russia Foundation for Humanities project «North American finns in soviet Karelia in 1920s to 1950s” // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 9-16.</span>
          <span>Takala Irina and Solomeshch Ilya (eds.). North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. 270 p.</span>
          <span>Takala I. ”Ikimuistoisen läksyn me kaikille luemme” – Suuren terrorin kansallisuusoperaatioiden erityispiirteitä Karjalassa // Historiallinen Aikakauskirja. 2017. № 3. S. 291-302.</span>
          <span>Takala I. Amerikansuomalaisten elämästä Neuvosto-Karjalassa löytyy yhä uutta aineistoa // Akti. 2017. № 3. S. 6-7.</span>
          <span>Takala Irina, Spazheva Irina. Historian vaiheita // Carelia. 2017. № 2. S. 31-47.</span>
          <span>Tzouliadis T. The Forsaken: An American Tragedy in Stalin’s Russia. London: Penguin Press, 2008. 448 p.</span>
          <span>Usacheva E. The documents about the north american finns in the National archive of Karelia // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 256-267.</span>
          <span>Vihavainen T., Takala I. (toim.). Yhtä suurta perhettä. Bolshevikkien kansallisuuspolitiikka Luoteis-Venäjällä 1920 -1950 -luvuilla. Helsinki, 2000. 326 s.</span>
          <span>Vihavainen T. Framing the Finnish Experience in the Soviet Union. Finns from Finland and Finns from North America Compared // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004. P. 141-151.</span>
          <span>Vähämäki B. Memoir accounts of finnish north Americans in soviet Karelia in the 1930s // North American Finns in Soviet Karelia in the 1930s. Petrozavodsk: Petrozav. State Univ., 2008. P. 152-169.</span>
          <span>Ylikangas M. The Experience of Finnish-North American Writers in Soviet Karelia in the 1930s // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depresson Era. Beaverton, Ontario, Canada: Aspasia Books, 2004. P. 152-175.</span>
          <span>Ylikangas M. Rivit suoriksi! Kaunokirjallisuuden poliittinen valvonta Neuvosto-Karjalassa 1917–1940. Helsinki: Kikimora Publications, 2004.</span>
        </p>
        <p>
          <span>Андриайнен А. И. Движение пролетарской солидарности зарубежных финских трудящихся с Советской Карелией // 50 лет Советской Карелии. Петрозаводск: Карелия, 1969. С. 180-198.</span>
          <span>Андриайнен А.И. Замечательный пример интернациональной пролетарской солидарности // Вопросы истории КПСС. Петрозаводск: Изд-во ПетрГУ, 1968. С. 87–101.</span>
          <span>Голубев А.В., Такала И.Р. В поисках социалистического Эльдорадо: североамериканские финны в Советской Карелии 1930-х годов / Авт. пер. с англ. А. С. Роговой. СПб.: Нестор-История, 2019. 352 с.</span>
          <span>В семье единой: Национальная политика партии большевиков и ее осуществление на Северо-Западе России в 1920–1950-е гг. / Под ред. Т. Вихавайнена и И. Такала. Петрозаводск: Изд-во ПетрГУ, 1998. 289 с.</span>
          <span>Ефремкин Е. «Карельский проект» или «карельская лихорадка»? // Учёные записки Петрозаводского государственного университета. Общественные и гуманитарные науки. 2008. № 3 (95). С. 42-51.</span>
          <span>Ефремкин Е. Переселение североамериканских финнов в Советскую Карелию в 1930-1933 годах: статистический анализ // Труды Карельского научного центра Российской академии наук. 2011. № 6. С. 97-105. (Серия: Гуманитарные исследования, Вып. 2).</span>
          <span>Иштонкова В.В. Как погасили «Луч». Последние годы коммуны «Säde» // Российские финны: вчера, сегодня, завтра. Петрозаводск, 2010. С. 76-86.</span>
          <span>Кивисто П. Реакция на переселение: ответ американских финнов на «карельскую лихорадку» // Учёные записки Петрозаводского государственного университета. Общественные и гуманитарные науки. 2008. № 3 (95). С. 33-41.</span>
          <span>Кочеткова Е.А. Вклад финнов-иммигрантов в строительство Кондопожского ЦБК (1920-1930-е годы) // Российские финны: вчера, сегодня, завтра. Петрозаводск, 2010. С. 70-76.</span>
          <span>Лаврушина Н. В. Из истории появления североамериканских финнов в Карелии в начале 1930-х годов // Карелы. Финны. Проблемы этнической истории. М.: ИЭА РАН, 1992. С. 176-189.</span>
          <span>Лайдинен Э.П. Хроника преследования (судьба семьи А. Ф. Нуортева) // Учёные записки ПетрГУ. Сер. Общественные и гуманитарные науки. 2009. № 8. С. 24–30.</span>
          <span>Линдстрем В. «Рай или ад на земле?». Пропагандистская война Советской Карелии 1934-1935 годов в освещении финской печати Канады // Учёные записки Петрозаводского государственного университета. Общественные и гуманитарные науки. 2008. № 3 (95).    С. 24-32.</span>
          <span>Линдстрём В. Канадские финны и десять лет Великой депрессии // Устная история в Карелии. Вып. 2. Североамериканские финны в Советской Карелии 1930-х гг. / Под ред. И. Р. Такала и А. В. Голубева. Петрозаводск: Изд-во ПетрГУ, 2007. С. 8-25.</span>
          <span>Машин А. Оборвавшаяся элегия // Их называли КР: репрессии в Карелии 20-30-х годов / сост. Анатолий Цыганков. Петрозаводск, 1992. С.245-252.</span>
          <span>Осипов А. Культурное наследие канадских финнов - журнал «Кулак» («Nyrkki») // Устная история в Карелии. Вып. 2. Североамериканские финны в Советской Карелии 1930-х гг. / Под ред. И. Р. Такала и А. В. Голубева. Петрозаводск: Изд-во ПетрГУ, 2007.  С. 26-31.</span>
          <span>Паасо В. Простите, простите, простите...: [о судьбе американских финнов-эмигрантов в Карелии] // Север. 1995. №10. С.114–131.</span>
          <span>Переплесин Б. В. Герой социалистического труда Э. М. Ярви. Петрозаводск: Госиздат КАССР, 1958. 26 с.</span>
          <span>Пименов В.Е., Тароева Р.Ф. Этнические процессы в Советской Карелии // 50 лет Советской Карелии. Петрозаводск: Карелия, 1970. С. 214-247.</span>
          <span>Сарамо С. Личные письма североамериканских финнов как источник изучения жизни Советской Карелии // Учёные записки Петрозаводского государственного университета. Общественные и гуманитарные науки. 2008. № 3 (95). С. 52-59.</span>
          <span>Севандер М. Скитальцы: О судьбах американских финнов в Карелии. Петрозаводск: Изд-во ПетрГУ, 2006. 186 с.</span>
          <span>Севандер М. Североамериканские финны в Карелии // Краевед: Сборник статей. Петрозаводск: Verso, 2007. С. 137-147.</span>
          <span>Сойни Е. Г. Поэзия-утопия финской иммиграции в России, 1920–1930-е годы // Финский фактор в истории и культуре Карелии ХХ в. / Под ред. О. П. Илюхи. Петрозаводск: КарНЦ РАН, 2009. С. 208-231.</span>
          <span>Такала И. Р. Финское население Советской Карелии в 1930-е годы // Карелы. Финны. Проблемы этнической истории. М.: ИЭА РАН, 1992. С. 150-176.</span>
          <span>Такала И. Р. В поисках Эльдорадо. Североамериканские финны в Советской Карелии 30-х годов // Вопросы истории Европейского Севера. Петрозаводск, 1993. С. 91-110.</span>
          <span>Такала И. Национальные операции ОГПУ/НКВД в Карелии // В семье единой: Национальная политика партии большевиков и ее осуществление на Северо-Западе России в 1920-1950-е годы. Петрозаводск, 1998. С.161-206.</span>
          <span>Такала И.Р. Финны-иммигранты // Финны в России: история, культура, судьбы. Петрозаводск: Изд-во ПетрГУ, 1998. С. 95-128.</span>
          <span>Такала И.Р. Финны в Кондопоге // Кондопожский край в истории Карелии и России. Петрозаводск; Кондопога: Русский Север, 2000. С. 210–216.</span>
          <span>Такала И.Р. Финны в Карелии и в России: История возникновения и гибели диаспоры. СПб.: Издательство «Журнал Нева», 2002. 172 с.</span>
          <span>Такала И.Р. Научно-исследовательский проект «Североамериканские финны в Советской Карелии в 1920-1950-е гг.» // Устная история в Карелии. Вып. 2. Североамериканские финны в Советской Карелии 1930-х гг. / Под ред. И. Р. Такала и А. В. Голубева. Петрозаводск: Изд-во ПетрГУ, 2007.  С. 164-167.</span>
          <span>Такала И.Р. Североамериканские финны в довоенной Карелии // Устная история в Карелии. Вып. 2. Североамериканские финны в Советской Карелии 1930-х гг. / Под ред. И. Р. Такала и А. В. Голубева. Петрозаводск: Изд-во ПетрГУ, 2007.  С. 32-51.</span>
          <span>Такала И.Р. Североамериканские финны в восприятии жителей Советской Карелии в 1930-е годы // Учёные записки Петрозаводского государственного университета. Общественные и гуманитарные науки. 2008. № 3 (95). С. 15-23.</span>
          <span>Такала И.Р. Финны Советской Карелии и их вклад в развитие республики (1920-е – первая половина 1930-х годов) // Финский фактор в истории и культуре Карелии XX века. Петрозаводск: КНЦ РАН, 2009. С. 107–148.</span>
          <span>Такала И.Р., Спажева И.А. Национальный театр Карелии: вехи истории // Альманах североевропейских и балтийских исследований. 2017. Вып. 2. [Электронный ресурс.] URL: <a href="http://nbsr.petrsu.ru/journal/article.php?id=786" target="_blank">http://nbsr.petrsu.ru/journal/article.php?id=786</a></span> 
          <span>Такала И.Р. Большой террор в Карелии // Альманах североевропейских и балтийских исследований. 2018. Вып. 3. [Электронный ресурс.] URL: <a href="http://nbsr.petrsu.ru/journal/article.php?id=1066" target="_blank">http://nbsr.petrsu.ru/journal/article.php?id=1066</a></span>
          <span>Такала И.Р. Этнические аспекты государственной репрессивной политики в Советской Карелии 1920-30-х годов // Этнокультурные и этнополитические процессы в Карелии от средних веков до наших дней. Петрозаводск: Изд-во КарНЦ РАН, 2019. С. 211-252.</span>
          <span>Тимонен А., Лапчинский Г. Композитор К. Э. Раутио. Петрозаводск: Карельское книжное изд-во, 1964. 46 с.</span>
          <span>Филимончик С. Н. Об участии иностранных рабочих в индустриализации Карелии в годы первых пятилеток // Вопросы истории Европейского Севера. Петрозаводск, 1987. С. 145-157.</span>
          <span>Чернышева Т. Лесоруб Эро Лейво. Петрозаводск: Госиздат КФССР, 1948. 16 с.</span>
        </p>
      </RowText>
      <RowText>
        <h3>{t('Художественная литература')}</h3>
        <p>
          <span>Helo L. Karjalan metsissä kasvaa paperia. Petroskoi Kirja, 1932. 16 s.</span>
          <span>Johansson O. Metsien iskurit: Näytelmä metsätyöläisten elämästä 4:ssa näytöksessä. L.: Kirja, 1932. 79 s.</span>
          <span>Johansson O. Uusille raiteille. Runoelma. Petroskoi: Kirja,1933. 23 s.</span>
          <span>Johansson O. ja Kustaa. Kirveen ja sahan sankarit. Petroskoi: Kirja, 1933. 92 s.</span>
          <span>Kustaa (Toivola Jukka). Juttuja: Kokoelma pakinoita. L.: Kirja, 1932. 85 s.</span>
          <span>Parras E. Valtamerien kahta puolta: Jutelmia ja kertomuksia. Petroskoi: Kirja, 1937. 55 s.</span>
          <span>Perttu A. Papaninin retkikunta: romaani. Helsinki; Jyväskylä: Minerva, 2006. 432 s.</span>
          <span>Rakettu on raudalla, tulesta on tuotu: Neuvosto-Karjalan suomenkielistä runoutta vuosilta 1917-1940 / Kokoelman laatineet ja toimittaneet T. Summanen ja A. Mishin. Petroskoi: Karjala, 1976. 374 s.</span>
          <span>Rautiainen E. Mereltä leipää hakemassa: Kertomuksia mailta ja meriltä. Petroskoi: Kirja, 1934. 203 s.</span>
          <span>Rutanen M. Työn laulu: Runoja. L.: Kirja, 1933. 56 s.</span>
          <span>Tuuri A. Ikitie: romaani. Helsinki: Otava, 2011. 431 s.</span>
        </p>
        <p>
          <span>Гордиенко А.А. Здесь мой дом: документальная повесть [лесоруб Эйно Вилхович Туоми]. Петрозаводск: Карелия, 1983. 62 с.</span>
          <span>Лесные мелодии: Рассказы писателей Карелии / Сост. М. Пахомова и А. Хурмеваара. Петрозаводск: Карельское книжное издательство, 1967. 260 с.</span>
          <span>Пертту А.П. Экспедиция Папанина: роман. Петрозаводск: Северное сияние, 2012. 438 с.</span>
        </p>
      </RowText>
      <RowText>
        <h3>{t('Библиографии')}</h3>
        <p>
          <span>Bibliografia 1918-1944 // Kruhse P., Uitto A. Suomen rajan takana 1918–1944: Suomenkielisen neuvostokirjallisuuden historia ja bibliografia. Jyväskylä: BTJ, 2008. S. 137-330.</span>
          <span>Bibliography // Alexey Golubev and Irina Takala. The Search for a Socialist El Dorado: Finnish Immigration to Soviet Karelia from the United States and Canada in the 1930s. East Lansing, MI: Michigan State University Press, 2014. P. 215-232.</span>
          <span>Lähdeluettelo // Kero R. Neuvosto-Karjalaa rakentamassa: pohjois-amerikan suomalaiset tekniikan tuojina 1930-luvun Neuvosto-Karjalassa. Helsinki, 1983. S. 218-224.</span>
          <span>Selected bibliography // Karelian Exodus: Finnish Communities in North America and Soviet Karelia during the Depression Era / ed. by Ronald Harpelle [et al.]. Beaverton, 2004. P. 216-223. (Journal of Finnish Studies; vol. 8, N 1 August 2004).</span>
        </p>
        <p>
          <span>Аннотированный библиографический указатель статей о североамериканских финнах в Карелии, опубликованных в журнале «Карело-Мурманский край» за 1930-1935 гг. / сост. В. Иштонкова // Устная история в Карелии: Сборник научных статей и источников. Вып. 2. Североамериканские финны в Советской Карелии 1930-х годов / Сост. и научн. ред. И.Р. Такала, А.В. Голубев. Петрозаводск: Изд-во ПетрГУ, 2007. С. 176-178.</span>
          <span>Библиография // Голубев А. В., Такала И. Р. В поисках социалистического Эльдорадо: североамериканские финны в Советской Карелии 1930-х годов / Авт. пер. с англ. А. С. Роговой. СПб.: Нестор-История, 2019. С. 322-343.</span>
          <span>Национальные писатели Карелии: финская эмиграция и политические репрессии 1930-х годов: биобиблиографический указатель / сост.: Н. А. Прушинская, Е. И. Такала. Петрозаводск: Национальная библиотека Республики Карелия, 2005. 124 с.</span>
          <span>Основные публикации о североамериканских финнах в Карелии / сост. И. Р. Такала // Устная история в Карелии: Сборник научных статей и источников. Вып. 2. Североамериканские финны в Советской Карелии 1930-х годов / Сост. и научн. ред. И.Р. Такала, А.В. Голубев. Петрозаводск: Изд-во ПетрГУ, 2007. С. 168-175.</span>
        </p>
      </RowText>
      <RowText><p><i>Сост. Ирина Такала, Мирья Яскеляйнен</i></p></RowText>
    </Container>
  );
};

export default Literature;
