import styled, { css } from 'styled-components';
import { colors } from './Settings';

export const Table = styled.table`
  color: ${colors.mainText};
  margin-bottom: 1rem;
  width: 100%;
`;

const styles = css`
  max-width: 400px;
  padding: .75rem 8px;
  vertical-align: top;
`;

export const Thead = styled.thead`
  border-bottom: 2px solid ${colors.primary};
  text-align: left;

  tr {
    th {
      ${styles};
      padding: 8px;
      text-align: left;
      vertical-align: bottom;

      &:first-child {
        width: 15%;
      }
    }
  }
`;

export const Tbody = styled.tbody`
  tr {
    border-top: 1px solid ${colors.gray300};
    &:hover {
      background-color: ${colors.gray100};
    }

    td {
      ${styles};

      span {
        display: block;
        padding-bottom: 8px;
      }

      svg {
        font-size: 18px;
      }
    }
  }
`;
