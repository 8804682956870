import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ImageWrapper } from '../../components-ui';
import { links } from '../../utils/pages';

const Section: FunctionComponent = () => (
  <ImageWrapper>
    <img alt="Карта Карелии" src="/client/assets/images/map2.jpg" />
    <Link
      to={`${links.search}/15`}
      title="Кондопога"
      target="_blank"
      style={{ position: 'absolute', left: '66.98%', top: '2.43%', width: '17.06%', height: '4.19%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/31`}
      title="Суна"
      target="_blank"
      style={{ position: 'absolute', left: '64.59%', top: '14%', width: '10.07%', height: '3.97%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/24`}
      title="Падозеро"
      target="_blank"
      style={{ position: 'absolute', left: '31.48%', top: '42.01%', width: '15.36%', height: '3.97%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/4`}
      title="Виданы"
      target="_blank"
      style={{ position: 'absolute', left: '50.17%', top: '46.75%', width: '11.69%', height: '4.74%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/41`}
      title="Шуя"
      target="_blank"
      style={{ position: 'absolute', left: '62.2%', top: '43.11%', width: '11.69%', height: '4.19%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/16`}
      title="Лехнаволок"
      target="_blank"
      style={{ position: 'absolute', left: '68.52%', top: '47.52%', width: '18.77%', height: '3.75%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/30`}
      title="Соломенное"
      target="_blank"
      style={{ position: 'absolute', left: '67.32%', top: '51.82%', width: '19.37%', height: '4.19%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/5`}
      title="Вилга"
      target="_blank"
      style={{ position: 'absolute', left: '54.69%', top: '54.02%', width: '8.96%', height: '3.97%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/9`}
      title="Иску (лесопункт)"
      target="_blank"
      style={{ position: 'absolute', left: '48.29%', top: '54.36%', width: '6.31%', height: '3.53%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/19`}
      title="Матросы"
      target="_blank"
      style={{ position: 'absolute', left: '40.36%', top: '57.99%', width: '13.4%', height: '4.41%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/27`}
      title="Пряжa"
      target="_blank"
      style={{ position: 'absolute', left: '29.61%', top: '69.57%', width: '12.88%', height: '3.53%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/17`}
      title="Лососинное"
      target="_blank"
      style={{ position: 'absolute', left: '57.25%', top: '74.75%', width: '18.17%', height: '3.97%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/37`}
      title="Хиилисуо (Петрозаводск)"
      target="_blank"
      style={{ position: 'absolute', left: '67.49%', top: '62.84%', width: '1.71%', height: '2.21%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/37`}
      title="Хиилисуо (Петрозаводск)"
      target="_blank"
      style={{ position: 'absolute', left: '59.3%', top: '62.29%', width: '8.19%', height: '3.09%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/26`}
      title="Петрозаводск"
      target="_blank"
      style={{ position: 'absolute', left: '69.45%', top: '60.97%', width: '19.88%', height: '3.64%', zIndex: 2 }}
    />
    <Link
      to={`${links.search}/26`}
      title="Петрозаводск"
      target="_blank"
      style={{ position: 'absolute', left: '67.75%', top: '60.53%', width: '1.71%', height: '2.21%', zIndex: 2 }}
    />
  </ImageWrapper>
);

export default Section;
