import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoStyleButton } from '.';
import { alphabets } from '../utils/const';
import { colors, breakpoints } from './Settings';

const Wrapper = styled.div`
  margin-top: 8px;
  margin-left: -4px;

  @media (max-width: ${breakpoints.s}) {
    max-width: 400px;
  }
`;

const LetterButton = styled(NoStyleButton)`
  color: ${colors.mainText};
  padding: 4px;
  font-size: 16px;
  font-weight: 600;

  &:hover, &.active {
    color: ${colors.primary};
  }
`;

export const Alphabet = ({ lang, onClick }): JSX.Element => {
  const [active, setActive] = useState();
  const { t } = useTranslation();

  const click = (item, index) => {
    onClick(index !== active ? item : '');
    setActive(index !== active ? index : '');
  };

  useEffect(() => {
    click('', active);
  }, [lang]);

  return (
    <Wrapper>
      {alphabets[lang].map((item, index) => (
        <LetterButton
          className={index === active ? 'active' : ''}
          type="button"
          onClick={(): void => click(item, index)}
        >{item}
        </LetterButton>
      ))}
      <LetterButton onClick={(): void => click('', active)}>{t('Все')}</LetterButton>
    </Wrapper>
  );
};
