import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Gallery from 'react-photo-gallery';
import { Container, Row } from '../components-ui';

const RowText = styled(Row)`
  margin-bottom: 32px;

  p {
    text-align: center;
  }

  .react-photo-gallery--gallery {
    width: 75%;
    margin: 0 auto;
  }
`;

const photos = [
  {
    src: '/client/assets/images/main1.jpg',
    width: 1000,
    height: 667,
  },
  {
    src: '/client/assets/images/main2.jpg',
    width: 1000,
    height: 987,
  },
  {
    src: '/client/assets/images/main3.jpg',
    width: 1000,
    height: 741,
  },
  {
    src: '/client/assets/images/main4.jpg',
    width: 1000,
    height: 662,
  },
  {
    src: '/client/assets/images/main5.jpg',
    width: 1000,
    height: 662,
  },
];


export const Home: FunctionComponent = () => (
  <Container>
    <RowText>
      <p>
        Интернет-проект представляет документы Национального архива Республики Карелия
        по истории финнов, прибывших в 1920‑1930‑е гг. в Cоветскую Карелию из Северной Америки
        и оказавших значительное влияние на развитие экономики, культуры, образования
        и спорта республики.
      </p>
    </RowText>
    <RowText>
      <p>
        Internet-projekti esittelee Karjalan tasavallan kansallisarkiston asiakirjoja niiden
        Amerikan ja Kanadan suomalaisten historiasta, jotka saapuivat Neuvosto-Karjalaan
        1920- ja 1930‑luvulla. Tulijoilla oli merkittävä vaikutus Karjalan tasavallan talouden,
        kulttuurin, koulutuksen ja urheilun kehitykseen.
      </p>
    </RowText>
    <RowText>
      <Gallery
        photos={photos}
        margin={4}
      />
    </RowText>
  </Container>
);
