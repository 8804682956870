import React from 'react';

/**
 * HoC for injecting data fetch functions and running them
 * on componentDidMount
 */
export default (fetchFunc: () => Promise<any>) => (
  WrappedComponent: React.Component<{}, {}>
) => class NewComponent extends React.Component<{}, {}> {
    fetchData = fetchFunc;

    componentDidMount(): void {
      this.fetchData();
    }

    render(): JSX.Element {
      return <WrappedComponent {...this.props} />;
    }
};
