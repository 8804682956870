import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PersonPhoto } from '../../../stores';
import { NoStyleButton } from '../../../components-ui';

interface Person {
  person: PersonPhoto;
  openLightbox(person: PersonPhoto): void;
}

export const Person: FunctionComponent<Person> = observer(({ person, openLightbox }) => {
  const { t, i18n } = useTranslation();
  const { source } = person;

  const openScans = (): void => {
    openLightbox(person);
  };

  return (
    <tr>
      <td>
        <span>{person.surnameRus} {person.nameRus}</span>
        <span>{person.surnameFin} {person.nameFin}</span>
      </td>
      <td>{source.title}</td>
      <td>{source.shootPlace || t('Не установлено')}</td>
      <td>{source.dateFormat}</td>
      <td>{source.author[i18n.language] || t('Не установлен')}</td>
      <td>{source.comment || '—'}</td>
      <td>
        <NoStyleButton onClick={openScans} title={t('Смотреть фото')}>
          <FontAwesomeIcon
            icon={['far', 'image']}
            className="fa-fw"
          />
        </NoStyleButton>
      </td>
    </tr>
  );
});
