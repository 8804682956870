import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PersonDocument } from '../../../stores';
import { States } from '../../../types/types';
import { NoStyleButton, PagesWithOverflow } from '../../../components-ui';

interface Person {
  person: PersonDocument;
  openLightbox(person: PersonDocument): void;
}

export const Person: FunctionComponent<Person> = observer(({ person, openLightbox }) => {
  const { t, i18n } = useTranslation();
  const { source } = person;

  const openScans = (): void => {
    if (source.scansLoadingState !== States.Done) source.fillScans();
    openLightbox(person);
  };

  return (
    <tr>
      <td>
        <span>{person.surnameRus} {person.nameRus}</span>
        <span>{person.surnameFin} {person.nameFin}</span>
      </td>
      <td>{t(person.homeland)}</td>
      <td>{person.placesNames.map((place) => <span key={place.fi}>{place[i18n.language]}</span>)}</td>
      <td>{person.workplacesNames.map((place) => <span key={place.fi}>{place[i18n.language]}</span>)}</td>
      <td>{source.title}</td>
      <td>{source.dateFormat}</td>
      <td><PagesWithOverflow>{source.references}</PagesWithOverflow></td>
      <td>{source.comment || '—'}</td>
      <td>
        {!!source.fileUrl && (
          source.scansLoadingState === States.Pending ? (
            <FontAwesomeIcon
              icon={['fas', 'circle-notch']}
              className="fa-spin"
            />
          ) : (
            <NoStyleButton onClick={openScans} title={t('Смотреть документ')}>
              <FontAwesomeIcon
                icon={['far', 'file-alt']}
                className="fa-fw"
              />
            </NoStyleButton>
          )
        )}
      </td>
    </tr>
  );
});
