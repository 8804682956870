import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, NoStyleButton } from '../../components-ui';
import { pages } from '../../utils/pages';

const NavBlock = styled.nav`
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  font-family: Roboto;
  background-color: ${colors.gray200};

  &.opened {
    transform: translateX(0);
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

const MenuItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ItemLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  padding: 16px 24px;

  &:not(span):hover,
  &:not(span).active {
    background-color: ${colors.primary};
    color: ${colors.white};
    text-decoration: none;
  }
`;

const SubMenu = styled.ul`
  padding-left: 24px;
`;

const Button = styled(NoStyleButton)`
  font-size: 20px;
  margin-bottom: 16px;
  margin-right: 24px;
  align-self: flex-end;
`;

const ButtonsWrapper = styled.div`
  padding: 0 0 36px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LanguageButton = styled(NoStyleButton)`
  width: 42px;
  height: 42px;
  border-radius: 0;
  font-weight: bold;
  margin-right: 8px;

  &.active,
  &:hover {
    border: 2px solid ${colors.primary};
    font-weight: bold;
  }
`;

interface Props {
  openedSideNav: boolean;
  toggleSideNav: () => void;
}

const SideNav: FunctionComponent<Props> = ({ openedSideNav, toggleSideNav }) => {
  const { t, i18n } = useTranslation();

  return (
    <NavBlock className={openedSideNav ? 'opened' : ''}>
      <ButtonsWrapper>
        <div>
          <LanguageButton
            type="button"
            className={i18n.language === 'ru' ? 'active' : undefined}
            onClick={(): void => { i18n.changeLanguage('ru'); localStorage.setItem('i18nextLng', 'ru'); }}
          >
            RU
          </LanguageButton>
          <LanguageButton
            type="button"
            className={i18n.language === 'fi' ? 'active' : undefined}
            onClick={(): void => { i18n.changeLanguage('fi'); localStorage.setItem('i18nextLng', 'fi'); }}
          >
            FI
          </LanguageButton>
        </div>
        <Button onClick={toggleSideNav} title={t('Закрыть меню')}>
          <FontAwesomeIcon
            icon={['fas', 'times']}
            className="fa-fw"
          />
        </Button>
      </ButtonsWrapper>
      <Menu>
        {pages.map(item => (
          <MenuItem key={item.name}>
            {item.children.length ? (
              <>
                <ItemLink as="span">{t(item.name)}</ItemLink>
                <SubMenu>
                  {item.children.map(child => (
                    <MenuItem key={child.name}>
                      <ItemLink to={child.link} activeClassName="active">
                        {t(child.name)}
                      </ItemLink>
                    </MenuItem>
                  ))}
                </SubMenu>
              </>
            ) : (
              <ItemLink exact to={item.link} activeClassName="active">
                {t(item.name)}
              </ItemLink>
            )}
          </MenuItem>
        ))}
      </Menu>
    </NavBlock>
  );
};

export default SideNav;
