import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { DocumentsList, Document } from '../views/Documents';
import { NoMatch } from './NoMatch';
import { links } from '../utils/pages';

export const Documents: FunctionComponent = () => (
  <Switch>
    <Route exact path={links.documents} component={DocumentsList} />
    <Route exact path={`${links.documents}/:id`} component={Document} />
    <Route component={NoMatch} />
  </Switch>
);
