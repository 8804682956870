import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonsBlock, LanguageButton, breakpoints, colors } from '../components-ui';
import { Hamburger, Overlay, SideNav, TopNav } from '../views/Navigation';

const ImageBlock = styled.div`
  background: url(/client/assets/images/header.jpg) center;
  height: 165px;
  background-size: cover;

  @media (max-width: ${breakpoints.m}) {
    height: 100px;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 16px;

  h1 {
    font-family: OpenSans;
    font-weight: normal;
    font-size: 24px;
    font-weight: 600;

    @media (min-width: ${breakpoints.xs}) {
      font-size: 26px;
    }

    @media (min-width: ${breakpoints.s}) {
      font-size: 28px;
    }

    @media (min-width: ${breakpoints.m}) {
      font-size: 30px;
    }
  }
`;

const TitlesWrapper = styled.div`
  width: 100%;
  padding-right: 24px;
`;

const ButtonWrapper = styled(ButtonsBlock)`
  margin-right: 16px;
`;

const HeaderStyle = styled.header`
  @media (max-width: ${breakpoints.m}) {
    .mobile-hide {
      display: none;
    }

    &:after {
      content: '';
      height: 2px;
      width: 85%;
      background-color: ${colors.primary};
      display: block;
      margin: 32px auto 0px;
    }
  }

  @media (min-width: 965px) {
    .mobile-show {
      display: none;
    }
  }
`;

interface Props {
  openedSideNav: boolean;
  toggleSideNav: () => void;
}

const Header: FunctionComponent<Props> = ({ openedSideNav, toggleSideNav }) => {
  const { t, i18n } = useTranslation();

  return (
    <HeaderStyle>
      <ImageBlock />
      <TitleBlock>
        <TitlesWrapper>
          <h1>{t('Североамериканские финны в Cоветской Карелии в 1920-1930-е гг.')}</h1>
        </TitlesWrapper>
        <Hamburger toggleSideNav={toggleSideNav} />
        <ButtonWrapper className="mobile-hide">
          <LanguageButton
            type="button"
            className={i18n.language === 'ru' ? 'active' : undefined}
            onClick={(): void => { i18n.changeLanguage('ru'); localStorage.setItem('i18nextLng', 'ru'); }}
          >
            RU
          </LanguageButton>
          <LanguageButton
            type="button"
            className={i18n.language === 'fi' ? 'active' : undefined}
            onClick={(): void => { i18n.changeLanguage('fi'); localStorage.setItem('i18nextLng', 'fi'); }}
          >
            FI
          </LanguageButton>
        </ButtonWrapper>
      </TitleBlock>
      <TopNav />
      <SideNav openedSideNav={openedSideNav} toggleSideNav={toggleSideNav} />
      {openedSideNav && <Overlay toggleSideNav={toggleSideNav} />}
    </HeaderStyle>
  );
};

export default Header;
