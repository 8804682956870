import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from './Settings';

interface Props {
  label?: string;
}

const Wrapper = styled.div`
  color: ${colors.mainText}
  svg {
    margin-right: 4px;
  }
`;

export const Loader: FunctionComponent<Props> = ({ label }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FontAwesomeIcon
        icon={['fas', 'circle-notch']}
        className="fa-spin"
      />
      {t(label || 'Идет загрузка')}
    </Wrapper>
  );
};
