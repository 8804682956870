import React, { FunctionComponent } from 'react';
import { observer, Provider } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorBoundary } from '@sentry/react';
import { Error } from './Error';
import { Home } from '../pages/Home';
import Preface from '../pages/Preface';
import Literature from '../pages/Literature';
import AbbreviationsList from '../pages/AbbreviationsList';
import About from '../pages/About';
import { Documents } from '../pages/Documents';
import Search from '../pages/Search';
import Photos from '../pages/Photos';
import { NoMatch } from '../pages/NoMatch';
import { RootStore } from '../rootStore';
import { PersonsSearch } from '../views/Persons';
import { links } from '../utils/pages';

interface Props {
  store: RootStore;
}

const MainWrapper = styled.main`
  flex: 1;
`;

const Main: FunctionComponent<Props> = ({ store }) => (
  <MainWrapper>
    <ErrorBoundary fallback={<Error />}>
      <Provider store={store}>
        <Switch>
          <Route exact path={links.home} component={Home} />
          <Route exact path={links.preface} component={Preface} />
          <Route path={links.documents} component={Documents} />
          <Route path={links.photos} component={Photos} />
          <Route path={links.search} component={Search} />
          <Route exact path={links.persons} component={PersonsSearch} />
          <Route exact path={links.literature} component={Literature} />
          <Route exact path={links.abbreviationsList} component={AbbreviationsList} />
          <Route exact path={links.about} component={About} />
          <Route component={NoMatch} />
        </Switch>
      </Provider>
    </ErrorBoundary>
  </MainWrapper>
);

export default withRouter(observer(Main));
