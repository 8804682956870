import { observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table as TTable, Thead, Tbody, OverflowWrapper, CarouselCustom } from '../../../components-ui';
import { Person } from './Person';
import { PersonDocument } from '../../../stores';
import { States } from '../../../types/types';


interface PersonDocumentsTable {
  documents: Array<PersonDocument>;
}

export const Table: FunctionComponent<PersonDocumentsTable> = observer(({ documents }) => {
  const { t } = useTranslation();
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [showingPerson, setShowingPerson] = useState(PersonDocument.create());

  const openLightbox = (person: PersonDocument): void => {
    setShowingPerson(person);
    setViewerIsOpen(true);
  };

  const closeLightbox = (): void => {
    setViewerIsOpen(false);
  };

  return (
    <OverflowWrapper>
      <TTable>
        <Thead>
          <tr>
            <th>{t('Фамилия, имя')}</th>
            <th>{t('Откуда прибыл(а)')}</th>
            <th>{t('Место нахождения/место жительства')}</th>
            <th>{t('Место работы')}</th>
            <th>{t('Заголовок документа')}</th>
            <th>{t('Дата документа')}</th>
            <th>{t('Поисковые данные документа')}</th>
            <th>{t('Примечание')}</th>
            <th>{t('Смотреть документ')}</th>
          </tr>
        </Thead>
        <Tbody>
          {documents.map((item) => (
            <Person key={item.id} person={item} openLightbox={openLightbox} />
          ))}
          {showingPerson.source.scansLoadingState === States.Done
            && (
            <CarouselCustom
              viewerIsOpen={viewerIsOpen}
              onClose={closeLightbox}
              currentIndex={showingPerson.findIndex}
              views={showingPerson.source.getScans}
            />
            )}
        </Tbody>
      </TTable>
    </OverflowWrapper>
  );
});
