import { observer } from 'mobx-react';
import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { Opacity, Loader, ListPagination, SemiTitle, TableWrapper } from '../../../components-ui';
import { States } from '../../../types/types';
import { Table } from './Table';
import { PersonsPhotos, SearchPersons } from '../../../stores';

interface ViewProps {
  photos: PersonsPhotos;
  search: SearchPersons;
  currentPage: number;
  setCurrentPage: (arg: number) => void;
}

const View: FunctionComponent<ViewProps> = ({ photos, search, currentPage, setCurrentPage }) => {
  const { t } = useTranslation();
  const { total, items, loadingState } = photos;
  const listRef = useRef(null);
  const [pageSwitched, setPageSwitched] = useState(false);

  const handlePageChange = (page): void => {
    setPageSwitched(true);
    setCurrentPage(page);
    photos.fetch(page, search);
  };

  useEffect(() => {
    if (pageSwitched && loadingState === States.Done && !!total) {
      listRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [loadingState]);

  return (
    <>
      <SemiTitle ref={listRef}>{t('Фотографии')}</SemiTitle>

      {loadingState === States.Pending && !total && <Loader />}

      <Opacity semi={loadingState === States.Pending}>
        {loadingState === States.Done && !total && (
          <p>
            {t('По данному запросу фотографий не найдено')}
          </p>
        )}

        {!!total && !!items && (
          <TableWrapper>
            <Table photos={items} />
            <ListPagination
              total={total}
              currentPageNumber={currentPage}
              handlePageChange={handlePageChange}
            />
          </TableWrapper>
        )}
      </Opacity>
    </>
  );
};

export default compose(
  observer
)(View);
