import { format } from 'date-fns';
import { DateTypes } from '../types/types';

interface DateObject {
  dateType: string;
  dateStart: Date;
  dateEnd?: Date;
  dateEstimate: boolean;
  }

export const dateFormat = (date: DateObject): string => {
  let data;
  switch (date.dateType) {
    case DateTypes.Full:
      data = format(date.dateStart, 'dd.MM.yyyy');
      break;
    case DateTypes.Year:
      data = format(date.dateStart, 'yyyy');
      break;
    case DateTypes.NotLaterFull:
      data = `Не позднее ${format(date.dateStart, 'dd.MM.yyyy')}`;
      break;
    case DateTypes.NotLaterYear:
      data = `Не позднее ${format(date.dateStart, 'yyyy')}`;
      break;
    case DateTypes.NotEarlierFull:
      data = `Не ранее ${format(date.dateStart, 'dd.MM.yyyy')}`;
      break;
    case DateTypes.NotEarlierYear:
      data = `Не ранее ${format(date.dateStart, 'yyyy')}`;
      break;
    case DateTypes.Decase:
      data = `${format(date.dateStart, 'yyyy')}-е`;
      break;
    case DateTypes.Range:
      data = `${format(date.dateStart, 'yyyy')} – ${format(date.dateEnd, 'yyyy')}-е`;
      break;
    default:
      break;
  }
  return date.dateEstimate ? `[${data}]` : data;
};

export const searchQuery = (search): string => {
  const searchArray = Object.keys(search).filter((key) => !!search[key]).map((key) => `${key}=${search[key]}`);
  return searchArray.length ? `&${searchArray.join('&')}` : '';
};
