import React, { FunctionComponent, useState, useCallback } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gallery from 'react-photo-gallery';
import { RootStore } from '../../rootStore';
import withFetch from '../../utils/withFetch';
import {
  ItalicPar,
  ItemWrapper,
  ItemInfo,
  ItemImages,
  ItemPlace,
  PageWrapper,
  Loader,
  IconLink,
  CarouselCustom
} from '../../components-ui';
import { States } from '../../types/types';
import { links } from '../../utils/pages';

interface MatchParams {
  id: string;
}

interface DocumentsProps extends RouteComponentProps<MatchParams> {
  store: RootStore;
}

interface ComponentProps {
  props: DocumentsProps;
}

const fetchData = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { store, match } = this.props;
    const { documentStore } = store;

    if (!!documentStore.title) {
      return Promise.resolve();
    }
    return documentStore.fetch(match.params.id, documentStore.scansLoadingState !== States.Done);
  };

  return Promise.resolve(fetchEntities());
});

const Document: FunctionComponent<DocumentsProps> = ({ store }) => {
  const { t, i18n } = useTranslation();
  const document = store.documentStore;
  const scans = document.getScans;

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = (): void => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <PageWrapper>
      <IconLink to={links.documents}>
        <FontAwesomeIcon
          icon={['fas', 'arrow-circle-left']}
          className="fa-fw"
        />
        {t('Вернуться к списку')}
      </IconLink>

      {document.loadingState === States.Pending && <Loader />}
      {document.loadingState === States.Error && (
        <p>Документ не найден</p>
      )}
      {document.loadingState === States.Done && (
        <>
          <h2>{document.title}</h2>
          <ItemWrapper>
            <ItemInfo>
              <p>
                <FontAwesomeIcon icon={['fas', 'calendar']} className="fa-fw" />
                {document.dateFormat}
              </p>

              <p>
                {document.isDocument ? (
                  <FontAwesomeIcon icon={['fas', 'archive']} className="fa-fw" />
                ) : (
                  <FontAwesomeIcon
                    icon={['fas', 'newspaper']}
                    className="fa-fw"
                  />
                )}
                {document.references}
              </p>
              {!!document.places.length && (
                <p>
                  <FontAwesomeIcon
                    icon={['fas', 'map-marker-alt']}
                    className="fa-fw"
                  />
                  {document.places.map((place) => <ItemPlace key={place.fi}>{place[i18n.language]}</ItemPlace>)}
                </p>
              )}
              {!!document.workplaces.length && (
                <p>
                  <FontAwesomeIcon icon={['fas', 'building']} className="fa-fw" />
                  {document.workplaces.map((place) => <ItemPlace key={place.fi}>{place[i18n.language]}</ItemPlace>)}
                </p>
              )}
              {!!document.comment && (
                <ItalicPar>{t('Примечание')}: {document.comment}</ItalicPar>
              )}
            </ItemInfo>
            {!!document.fileUrl && (
              <ItemImages>
                {document.scansLoadingState === States.Pending && <Loader label="Идет загрузка сканов" />}
                {document.scansLoadingState === States.Done && (
                  <>
                    <Gallery
                      photos={scans}
                      targetRowHeight={250}
                      onClick={openLightbox}
                    />
                    <CarouselCustom
                      viewerIsOpen={viewerIsOpen}
                      onClose={closeLightbox}
                      currentIndex={currentImage}
                      views={scans}
                    />
                  </>
                )}
              </ItemImages>
            )}
          </ItemWrapper>
        </>
      )}
    </PageWrapper>
  );
};

export default compose(inject('store'), fetchData, observer)(Document);
