import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withFetch from '../../utils/withFetch';
import { ListPagination, Opacity, Loader } from '../../components-ui';
import { States } from '../../types/types';
import { FETCH_COUNT } from '../../utils/const';
import { SearchDocs, PhotosStore } from '../../stores';
import { PhotoGrid } from '../Photos';

interface Props {
  store: PhotosStore;
  search: SearchDocs;
  hide: boolean;
  currentPage: number;
  setCurrentPage: (arg: number) => void;
  offset: number;
  setOffset: (arg: number) => void;
}

interface ComponentProps {
  props: Props;
}

const fetchData = withFetch(function fetch(this: ComponentProps): Promise<void> {
  const fetchEntities = (): Promise<void> => {
    const { store, search } = this.props;
    if (store.items.length
      || (store.loadingState === States.Pending
      || store.loadingState === States.Done)) {
      return Promise.resolve();
    }

    return store.fetch(1, search);
  };

  return Promise.resolve(fetchEntities());
});

const Photos: FunctionComponent<Props> = ({
  store,
  search,
  hide,
  currentPage,
  setCurrentPage,
  offset,
  setOffset,
}) => {
  const { t } = useTranslation();
  const { items, total, loadingState } = store;

  const listRef = useRef(null);
  const [pageSwitched, setPageSwitched] = useState(false);

  const handlePageChange = (page): void => {
    setPageSwitched(true);
    setCurrentPage(page);
    setOffset(Math.ceil((page - 1) * FETCH_COUNT));
    store.fetch(page, search);
  };

  useEffect(() => {
    if (pageSwitched && loadingState === States.Done && !!total) {
      listRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [loadingState]);

  if (hide) {
    return null;
  }

  return (
    <>
      {loadingState === States.Pending && !total && <Loader />}

      <Opacity semi={loadingState === States.Pending}>
        {loadingState === States.Done && !total && (
          <p>
            {t('По данному запросу фотографий не найдено')}
          </p>
        )}

        {!!total && !!items && (
        <div ref={listRef}>
          <PhotoGrid photos={items} offset={offset} />
          <ListPagination
            total={total}
            currentPageNumber={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
        )}
      </Opacity>
    </>
  );
};

export default compose(
  fetchData,
  observer
)(Photos);
