import { flow, Instance, types } from 'mobx-state-tree';
import { Place } from './models';
import { States, Languages } from '../types/types';
import { http } from '../http';
import { defaultLanguage } from '../utils/const';

const storeName = 'PlacesStore';
export const PlacesStore = types
  .model(storeName, {
    items: types.optional(types.array(Place), []),
    total: types.optional(types.number, 0),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(States)), States.Initial),
  })
  .views((self) => ({
    get lang(): string {
      return localStorage.getItem('i18nextLng') || defaultLanguage;
    },
    get needFetch(): boolean {
      return !!self.items.length || (self.loadingState === States.Pending || self.loadingState === States.Done);
    },
    find(id: number | undefined, language: string) {
      if (!id || self.loadingState !== States.Done) { return false; }
      const place = self.items.find((place) => place.id === id);
      return place
        ? {
          label: place[language],
          value: place.id,
        }
        : false;
    },
  }))
  .actions((self) => ({
    group(language, labels) {
      const group = self.items.reduce((accumulator, current) => {
        accumulator[current.type] = [...accumulator[current.type] || [], current];
        return accumulator;
      }, {});
      return ([
        {
          label: labels.locality,
          options: group.locality.sort((a, b) => a[language].localeCompare(b[language], language)).map((item) => ({
            label: item[language],
            value: item.id,
          })),
        },
        {
          label: labels.region,
          options: group.region.sort((a, b) => a[language].localeCompare(b[language], language)).map((item) => ({
            label: item[language],
            value: item.id,
          })),
        },
        {
          label: labels.noData,
          options: group.no_data.map((item) => ({
            label: item[language],
            value: item.id,
          })),
        },
      ]);
    },
    fetch: flow(function* fetch() {
      self.loadingState = States.Pending;
      try {
        const response = yield http.get('places', { headers: { 'X-Accept-Language': Languages[self.lang] } });

        self.loadingState = States.Done;
        const records = response.data
          .map((r) => {
            r.id = +r.id;
            return r;
          });


        self.items = records;
        self.total = records.length;
      } catch (error) {
        console.error('Failed to fetch places', error);
        self.loadingState = States.Error;
      }
    }),
  }));

export interface Places extends Instance<typeof PlacesStore> {
}
