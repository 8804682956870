import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import RootStore from './rootStore';

import './i18n';

const store = RootStore.create({}, { api: '/api/' });

Sentry.init({
  dsn: 'https://45ff04f14d184b98a20110525058d955@o248214.ingest.sentry.io/6179541',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

render((
  <BrowserRouter>
    <App store={store} />
  </BrowserRouter>
), document.getElementById('root'));
