export enum links {
  home = '/',
  preface = '/preface',
  documents = '/documents',
  photos = '/photos',
  search = '/search',
  persons = '/persons',
  literature = '/literature',
  about = '/about',
  abbreviationsList = '/abbreviations-list'
}

export const pages = [
  {
    name: 'Главная',
    link: links.home,
    children: [],
  },
  {
    name: 'Предисловие',
    link: links.preface,
    children: [],
  },
  {
    name: 'Электронный архив',
    link: '',
    children: [
      {
        name: 'Документы',
        link: links.documents,
      },
      {
        name: 'Фотографии',
        link: links.photos,
      },
      {
        name: 'Поиск по электронному архиву',
        link: links.search,
      },
    ],
  },
  {
    name: 'Поиск по персоналиям',
    link: links.persons,
    children: [],
  },
  {
    name: 'Литература',
    link: links.literature,
    children: [],
  },
  {
    name: 'Список сокращений',
    link: links.abbreviationsList,
    children: [],
  },
  {
    name: 'О проекте',
    link: links.about,
    children: [],
  },
];
