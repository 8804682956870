import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, breakpoints } from './Settings';
import { AdaptiveWrapper } from './Wrappers';

export const ListWrapper = styled(AdaptiveWrapper)`
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 24px;

  h2 {
    padding-bottom: 16px;
  }
`;

export const SearchIcon = styled(Link)`
  text-decoration: underline;

  &:hover {
    color: ${colors.primary}
  }

  svg {
    margin-right: 4px;
  }
`;

export const ListItem = styled(Link)`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.primary};
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background-color: ${colors.gray100};
  }
`;

export const ListItemInfo = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div > p {
    padding-bottom: 16px;
  }

  svg {
    margin-right: 8px;
  }
`;

export const PagesWithOverflow = styled.span`
  @media (max-width: ${breakpoints.xs}) {
    display: block;
    max-height: 64px;
    padding: 0 !important;
  
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
