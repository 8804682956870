import { flow, types } from 'mobx-state-tree';
import { DocumentsStore, PhotosStore, SearchDocs } from '.';
import { States } from '../types/types';
import { http } from '../http';
import { searchQuery } from '../utils/parsers';

const storeName = 'SearchStore';
export const SearchStore = types
  .model(storeName, {
    documents: types.optional(DocumentsStore, {}),
    photos: types.optional(PhotosStore, {}),
    searchHistory: types.optional(SearchDocs, {}),
  })
  .actions((self) => ({
    setSearch(search: SearchDocs): void {
      self.searchHistory = search;
    },
  }));
