import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, Row } from '../components-ui';

const RowText = styled(Row)`
  margin-bottom: 12px;

  p {
    text-indent: 12px;
    padding: 4px 8px;
  }

  h3 {
    margin-bottom: 16px;
    margin-top: 32px;
  }

  &:last-child {
    margin-top: 32px;
  }
`;

const About: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <RowText dangerouslySetInnerHTML={{ __html: t('Описание-1') }} />
      <RowText dangerouslySetInnerHTML={{ __html: t('Описание-2') }} />
      <RowText dangerouslySetInnerHTML={{ __html: t('Описание-3') }} />
      <RowText>
        <p>
          <strong>{t('Описание-4')}</strong>
        </p>
      </RowText>
    </Container>
  );
};

export default About;
