import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { colors } from '../../components-ui';
import { pages } from '../../utils/pages';

const NavBlock = styled.nav`
  align-items: center;
  background: ${colors.header};
  display: flex;
  font-family: Roboto;
  height: 75px;
  justify-content: space-between;
`;

const Menu = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
`;

const MenuItem = styled.li<{ subMenu?: boolean }>`
  flex-grow: 1;
  text-align: center;
  flex-basis: 100px;
  display: table;
  height: 100%;
  cursor: ${({ subMenu }): string => (subMenu ? 'default' : 'pointer')};
  position: relative;

  &:hover {
    ul {
      display: block;
    }

    & > span:not(.active) {
      background-color: ${colors.primaryLight};
      color: ${colors.white};
      text-decoration: none;
    }
  }
`;

const ItemLink = styled(NavLink)`
  display: table-cell;
  vertical-align: middle;
  color: ${colors.black};
  width: 100%;
  height: 100%;
  padding: 0 24px;

  &:hover,
  &.active {
    background-color: ${colors.primary};
    color: ${colors.white};
    text-decoration: none;
  }

  &:not(.active):hover {
    background-color: ${colors.primaryLight};
  }
`;

const SubMenu = styled.ul`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: ${colors.white};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);

  li {
    min-height: 32px;
    display: inline-block;
    width: 100%;
    
    a {
      padding: 8px 4px;
      display: block;

      &:hover,
      &.active {
        background-color: ${colors.gray300};
        color: ${colors.black};
        text-decoration: none;
      }

      &:not(.active):hover {
        background-color: ${colors.header};
      }
    }
  }
`;

interface Props {
  item: {
    name: string;
    link: string;
    children: Array<{
      name: string;
      link: string;
    }>;
  };
}

const SubMenuWrapper: FunctionComponent<Props> = ({ item }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { name, children } = item;

  const itemClass = children.some((child) => location.pathname.includes(child.link)) ? 'active' : '';

  return (
    <>
      <ItemLink as="span" className={itemClass}>{t(name)}</ItemLink>
      <SubMenu>
        {children.map((child) => (
          <MenuItem key={child.name}>
            <ItemLink to={child.link} activeClassName="active">
              {t(child.name)}
            </ItemLink>
          </MenuItem>
        ))}
      </SubMenu>
    </>
  );
};

const TopNav: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <NavBlock className="mobile-hide">
      <Menu>
        {pages.map((item) => (
          <MenuItem key={item.name} subMenu={!!item.children.length}>
            {item.children.length ? (
              <SubMenuWrapper item={item} />
            ) : (
              <ItemLink exact to={item.link} activeClassName="active">
                {t(item.name)}
              </ItemLink>
            )}
          </MenuItem>
        ))}
      </Menu>
    </NavBlock>
  );
};

export default TopNav;
