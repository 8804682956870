import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
  toggleSideNav: () => void;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.3);
  z-index: 100;
`;

const Backdrop: FunctionComponent<Props> = ({ toggleSideNav }) => (
  <Wrapper onClick={toggleSideNav} />
);

export default Backdrop;
